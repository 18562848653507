const headers = {
  name: "Purchase paid",
  navigate: {
    path: [
      {
        name: "Paid",
        path: "/app/purchases/paid",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
