const forms = [
  {
    name: "Transfer",
    options: [
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "name",
          placeholder: "Transfer date",
          class: "",
          value: "",
          required: true,
        },
      },
      // {
      //   name: "Product",
      //   type: "add",
      //   form: {
      //     name: "inventory",
      //     allowClear: true,
      //     showSearch: true,
      //     filterOption: true,
      //     placeholder: "Transfer product",
      //     value: null,
      //     options: [],
      //     required: false,
      //     path: "/app/inventory/add",
      //   },
      // },
      // {
      //   name: "Stock",
      //   type: "input",
      //   form: {
      //     type: "number",
      //     name: "stock",
      //     placeholder: "Transfer stock",
      //     class: "",
      //     value: "",
      //     required: true,
      //   },
      // },
    ],
  },
  {
    name: "Branch",
    options: [
      {
        name: "Branch",
        type: "add",
        form: {
          name: "inventory",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Transfer branch",
          value: null,
          options: [],
          required: false,
          path: "/app/settings/branches/add",
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
  {
    name: "Items",
    options: [
      {
        name: "Items",
        type: "component",
      },
    ],
  },
  // {
  //   name: "Items",
  //   options: [
  //     {
  //       name: "Details",
  //       type: "formtable",
  //       form: {
  //         name: "details",
  //         type: "selecttable",
  //         options: null,
  //         head: [
  //           { name: "Description" },
  //           { name: "Price" },
  //           { name: "Quantity" },
  //           { name: "Unit" },
  //           { name: "Tax" },
  //           { name: "Tax amount" },
  //           { name: "Total" },
  //         ],
  //         body: [
  //           {
  //             Description: {
  //               type: "select",
  //               form: {
  //                 name: "description",
  //                 allowClear: true,
  //                 showSearch: true,
  //                 filterOption: false,
  //                 placeholder: "Select Description",
  //                 value: null,
  //                 options: null,
  //                 api: "get-all-items",
  //                 required: true,
  //               },
  //             },
  //             Quantity: {
  //               type: "input",
  //               form: {
  //                 name: "quantity",
  //                 type: "number",
  //                 placeholder: "Description quantity",
  //                 value: "",
  //                 disabled: true,
  //                 required: true,
  //               },
  //             },
  //             Price: {
  //               type: "input",
  //               form: {
  //                 name: "price",
  //                 type: "number",
  //                 placeholder: "Description price",
  //                 value: "",
  //                 disabled: true,
  //                 required: true,
  //               },
  //             },
  //             Unit: {
  //               type: "text",
  //               form: {
  //                 name: "unit",
  //                 value: "-",
  //               },
  //             },
  //             Tax: {
  //               type: "input",
  //               form: {
  //                 name: "tax",
  //                 type: "number",
  //                 placeholder: "Description tax",
  //                 value: "",
  //                 disabled: true,
  //                 required: false,
  //               },
  //             },
  //             "Tax amount": {
  //               type: "text",
  //               form: {
  //                 name: "tax_amount",
  //                 value: 0,
  //               },
  //             },
  //             Total: {
  //               type: "text",
  //               form: {
  //                 name: "total",
  //                 value: 0,
  //               },
  //             },
  //           },
  //         ],
  //         foot: [
  //           {
  //             Description: "",
  //             Unit: "",
  //             Quantity: "",
  //             Price: "",
  //             "Tax amount": {
  //               type: "text",
  //               span: 2,
  //               form: {
  //                 value: "Subtotal",
  //               },
  //             },
  //             Total: {
  //               type: "text",
  //               form: {
  //                 name: "subtotal",
  //                 value: 0,
  //               },
  //             },
  //           },
  //           {
  //             Description: "",
  //             Unit: "",
  //             Quantity: "",
  //             Price: "",
  //             "Tax amount": {
  //               type: "text",
  //               span: 2,
  //               form: {
  //                 value: "Tax",
  //               },
  //             },
  //             Total: {
  //               type: "text",
  //               form: {
  //                 name: "tax",
  //                 value: 0,
  //               },
  //             },
  //           },
  //           {
  //             Description: "",
  //             Unit: "",
  //             Quantity: "",
  //             Price: "",
  //             "Tax amount": {
  //               type: "text",
  //               span: 2,
  //               form: {
  //                 value: "Discount",
  //               },
  //             },
  //             Total: {
  //               type: "input",
  //               form: {
  //                 name: "discount",
  //                 type: "number",
  //                 placeholder: "Discount",
  //                 value: "",
  //               },
  //             },
  //           },
  //           {
  //             Description: "",
  //             Unit: "",
  //             Quantity: "",
  //             Price: "",
  //             "Tax amount": {
  //               type: "text",
  //               span: 2,
  //               form: {
  //                 value: "Delivery",
  //               },
  //             },
  //             Total: {
  //               type: "input",
  //               form: {
  //                 name: "delivery",
  //                 type: "number",
  //                 placeholder: "Delivery",
  //                 value: "",
  //               },
  //             },
  //           },
  //           {
  //             Description: "",
  //             Unit: "",
  //             Quantity: "",
  //             Price: "",
  //             "Tax amount": {
  //               type: "text",
  //               span: 2,
  //               form: {
  //                 className: "text-black text-base",
  //                 value: "Total",
  //               },
  //             },
  //             Total: {
  //               type: "text",
  //               form: {
  //                 name: "total",
  //                 className: "text-black text-base",
  //                 value: 0,
  //               },
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },
];

export default forms;
