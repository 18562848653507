import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import expenses from "../../../Data/expenses.json";

const ExpensesReport = () => {
  let location = useLocation();

  const [reports, setReports] = useState();

  const headers = {
    name: "Expenses",
    components: [
      {
        type: "search",
        name: "search",
        placeholder: "Search",
        value: "",
      },
      {
        type: "range",
        name: "date",
        value: {
          start: "",
          end: "",
        },
      },
    ],
  };

  const fetchData = (tableData) => {
    let data = [];

    if (tableData) {
      tableData?.map((value, index) => {
        data?.push({
          date: value?.date,
          category: value?.category,
          amount: value?.amount,
        });
      });
    } else {
      expenses?.map((value, index) => {
        data?.push({
          date: value?.date,
          category: value?.category,
          amount: value?.amount,
        });
      });
    }

    setReports(data);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...expenses];

    let search = data?.search?.toLowerCase();
    let date = data?.date;

    if (search) {
      tableData = tableData.filter((item) =>
        item?.category?.toLowerCase()?.includes(search)
      );
    }

    if (date && date.start && date.end) {
      const startDate = new Date(date.start);
      const endDate = new Date(date.end);

      tableData = tableData.filter((item) => {
        const itemDate = parseDate(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <table className="w-full bg-white">
        <thead className="text-gray-500">
          <tr>
            <td className="border p-4">Date</td>
            <td className="border p-4">Category</td>
            <td className="border p-4">Amount</td>
          </tr>
        </thead>
        <tbody>
          {reports?.map((value, index) => {
            return (
              <tr>
                <td className="border p-4">{value?.date}</td>
                <td className="border p-4">{value?.category}</td>
                <td className="border p-4">{value?.amount}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.amount),
                0
              )
              ?.toFixed(3)}
          </td>
        </tfoot>
      </table>
    </Header>
  );
};

export default ExpensesReport;
