import React from "react";

const ValueTag = (props) => {
  let value = props?.value;
  let color = props?.color;
  let tag = props?.tag;

  return (
    <div>
      <span>{value}</span>
      <span
        // className={
        //   value?.notify == 1
        //     ? "ml-2 bg-red-200 text-red-800 text-xs text-medium rounded-lg py-1 px-2"
        //     : value?.notify == 0
        //     ? "ml-2 bg-orange-200 text-orange-800 text-xs text-medium rounded-lg py-1 px-5"
        //     : "ml-2 bg-green-200 text-green-800 text-xs text-medium rounded-lg py-1 px-2"
        // }
        className={`ml-2 bg-${color}-200 text-${color}-800 text-xs text-medium rounded-lg py-1 px-2`}
      >
        {/* {value?.notify == 1
          ? "Out Of Stock"
          : value?.notify == 0
          ? "Low"
          : "Stocked"} */}
        {tag}
      </span>
    </div>
  );
};

export default ValueTag;
