import React from "react";
import DotIcon from "../../Assets/Svg/dot";

const Status = (props) => {
  let name = props?.name;
  let color = props?.color;
  let status = props?.status;

  let currentColor = color
    ? color
    : !color && !status
    ? "text-red-500"
    : !color && status
    ? "text-green-500"
    : "";

  let currentname = name
    ? name
    : !name && !status
    ? "Inactive"
    : !name && status
    ? "Active"
    : "-";

  return (
    <div className="flex">
      <div className={`my-auto pr-2 ${currentColor}`}>
        <DotIcon width={10} height={10} />
      </div>
      <div>{currentname}</div>
    </div>
  );
};

export default Status;
