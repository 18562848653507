const forms = [
  {
    name: "Received",
    options: [
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "name",
          placeholder: "Received date",
          class: "",
          value: "",
          required: true,
        },
      },
      // {
      //   name: "Product",
      //   type: "add",
      //   form: {
      //     name: "inventory",
      //     allowClear: true,
      //     showSearch: true,
      //     filterOption: true,
      //     placeholder: "Received product",
      //     value: null,
      //     options: [],
      //     required: false,
      //     path: "/app/inventory/add",
      //   },
      // },
      // {
      //   name: "Stock",
      //   type: "input",
      //   form: {
      //     type: "number",
      //     name: "stock",
      //     placeholder: "Received stock",
      //     class: "",
      //     value: "",
      //     required: true,
      //   },
      // },
    ],
  },
  // {
  //   name: "Branch",
  //   options: [
  //     {
  //       name: "Branch",
  //       type: "add",
  //       form: {
  //         name: "inventory",
  //         allowClear: true,
  //         showSearch: true,
  //         filterOption: true,
  //         placeholder: "Received branch",
  //         value: null,
  //         options: [],
  //         required: false,
  //         path: "/app/settings/branches/add",
  //       },
  //     },
  //   ],
  // },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Received",
            },
            {
              value: 0,
              label: "Pending",
            },
          ],
          required: false,
        },
      },
    ],
  },
  {
    name: "Items",
    options: [
      {
        name: "Items",
        type: "component",
      },
    ],
  },
];

export default forms;
