import React from "react";

const ProductIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g transform="matrix(0.9299999999999999,0,0,0.9299999999999999,17.920002136230465,17.919997079372422)">
        <path
          d="m111.264 70.942-92.367 49.023a35.543 35.543 0 0 0-4.488 2.806L250.6 245.934l98.905-48.92L111.264 70.942zM511.954 150.122l-118.163 58.444v65.843c0 8.281-6.714 14.995-14.995 14.995-8.281 0-14.995-6.714-14.995-14.995V223.4l-98.386 48.663v238.295c.308-.143.62-.275.925-.427l226.182-114.605c12.016-6.008 19.478-18.08 19.478-31.519v-212.36c0-.445-.03-.884-.046-1.325zM492.522 119.927 266.433 5.37l-.094-.047c-10.067-5.012-22.029-4.9-32.002.3l-91.062 48.329 239.37 126.669 115.54-57.147a35.601 35.601 0 0 0-5.663-3.547zM.071 149.117A35.53 35.53 0 0 0 0 151.186v212.881c0 13.162 7.24 25.118 18.896 31.221L234.241 509.58l.097.051c.358.187.724.352 1.087.525V271.843L.071 149.117z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default ProductIcon;
