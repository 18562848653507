import { Input, Select } from "antd";
import React, { createRef, useEffect, useState } from "react";
import AddButton from "../Buttons/add";
import Error from "../Error";
import { select_search } from "../../Controllers/Global";
import { DeleteOutlined } from "@ant-design/icons";

const Formtable = React.forwardRef((props, ref) => {
  const { form, setForm, index, ind } = props;

  const handleItem = {
    add: () => {
      let data = { ...form[index].options[ind].form };

      let body = [...data?.body];

      let newRow = JSON.parse(JSON.stringify(data.body[0]));

      newRow.Description.form.value = null;
      newRow.Unit.form.value = "-";
      newRow.Quantity.form.value = "";
      newRow.Quantity.form.disabled = true;
      newRow.Price.form.value = "";
      newRow.Price.form.disabled = true;
      newRow.Tax.form.value = "0%";
      newRow["Tax amount"].form.value = 0;
      newRow.Total.form.value = 0;

      body.push(newRow);

      data.body = body;

      form[index].options[ind].form = data;
      setForm([...form]);
    },
    remove: (bodyIndex) => {
      let data = { ...form[index].options[ind].form };

      if (bodyIndex > 0) {
        data?.body?.splice(bodyIndex, 1);

        let subtotal = 0;
        let total_tax = 0;
        let discount = data.foot[2].Total.form.value
          ? data.foot[2].Total.form.value
          : 0;
        let delivery = data.foot[3].Total.form.value
          ? data.foot[3].Total.form.value
          : 0;
        let grand_total = 0;

        data?.body?.map((value, index) => {
          subtotal =
            parseFloat(subtotal ? subtotal : 0) +
            parseFloat(
              value?.Price?.form?.value ? value?.Price?.form?.value : 0
            ) *
              parseFloat(
                value?.Quantity?.form?.value ? value?.Quantity?.form?.value : 0
              );

          total_tax =
            parseFloat(total_tax ? total_tax : 0) +
            parseFloat(
              value?.["Tax amount"]?.form?.value
                ? value?.["Tax amount"]?.form?.value
                : 0
            );
        });

        grand_total =
          parseFloat(subtotal) +
          parseFloat(total_tax) +
          parseFloat(delivery) -
          parseFloat(discount);

        data.foot[0].Total.form.value = subtotal?.toFixed(3);
        data.foot[1].Total.form.value = total_tax?.toFixed(3);
        data.foot[4].Total.form.value = grand_total?.toFixed(3);

        form[index].options[ind].form = data;
        setForm([...form]);
      }
    },
    select: (e, bodyIndex, name) => {
      let data = { ...form[index].options[ind].form };

      let added_items = [];

      data?.body?.map((body, bodyIndex) => {
        data?.head?.map((head, headIndex) => {
          let tableBodyData = body?.[head?.name];

          if (Object?.keys(body)?.includes(head?.name)) {
            console.log(tableBodyData?.type == "select");
            if (tableBodyData?.type == "select") {
              added_items?.push(data.body[bodyIndex][head?.name].form.value);
            }
          }
        });
      });

      console.log(added_items?.includes(e));

      if (!added_items?.includes(e)) {
        let description;
        data?.options?.map((value, index) => {
          if (value?.batch_number == e) {
            description = value;
          }
        });

        data.body[bodyIndex].Quantity.form.disabled = e ? false : true;
        data.body[bodyIndex].Price.form.disabled = e ? false : true;
        data.body[bodyIndex].Tax.form.disabled = e ? false : true;

        console.log(description, "descriptiondescription", data);

        // let item_name = e ? description?.id : null;
        let item_name = e;
        let unit = e ? (description?.units ? description?.units : "-") : "-";
        let quantity = e ? 1 : 0;
        let price = e ? parseFloat?.(description?.purchase_price) : 0;
        // let price = 0;

        // let tax = 0;
        let tax = e ? parseFloat?.(description?.tax) : 0;

        let tax_amount = e
          ? parseFloat?.(price) * (parseFloat?.(description?.tax) / 100)
          : 0;
        // let tax_amount = 0;
        let total = e ? parseFloat?.(price) + parseFloat?.(tax_amount) : 0;

        data.body[bodyIndex].Description.form.value = item_name;
        data.body[bodyIndex].Unit.form.value = unit;
        data.body[bodyIndex].Quantity.form.value = quantity?.toFixed(3);
        data.body[bodyIndex].Price.form.value = price?.toFixed(3);
        data.body[bodyIndex].Tax.form.value = tax;
        data.body[bodyIndex]["Tax amount"].form.value = tax_amount?.toFixed(3);
        data.body[bodyIndex].Total.form.value = total?.toFixed(3);

        let subtotal = 0;
        let total_tax = 0;
        let discount = data.foot[2].Total.form.value
          ? data.foot[2].Total.form.value
          : 0;
        let delivery = data.foot[3].Total.form.value
          ? data.foot[3].Total.form.value
          : 0;
        let grand_total = 0;

        data?.body?.map((value, index) => {
          subtotal =
            parseFloat(subtotal ? subtotal : 0) +
            parseFloat(
              value?.Price?.form?.value ? value?.Price?.form?.value : 0
            ) *
              parseFloat(
                value?.Quantity?.form?.value ? value?.Quantity?.form?.value : 0
              );

          total_tax =
            parseFloat(total_tax ? total_tax : 0) +
            parseFloat(
              value?.["Tax amount"]?.form?.value
                ? value?.["Tax amount"]?.form?.value
                : 0
            );
        });

        grand_total =
          parseFloat(subtotal) +
          parseFloat(total_tax) +
          parseFloat(delivery) -
          parseFloat(discount);

        data.foot[0].Total.form.value = subtotal?.toFixed(3);
        data.foot[1].Total.form.value = total_tax?.toFixed(3);
        data.foot[4].Total.form.value = grand_total?.toFixed(3);

        form[index].options[ind].form = data;
        setForm([...form]);
      }
    },
    change: (e, bodyIndex, name) => {
      let data = { ...form[index].options[ind].form };

      if (e?.target?.value > 0 || e?.target?.value == "") {
        data.body[bodyIndex][name].form.value = e?.target?.value;

        let price = 0;
        let quantity = 0;
        let tax_amount = 0;
        let total = 0;
        let tax = data.body[bodyIndex].Tax.form.value
          ? data.body[bodyIndex].Tax.form.value
          : 0;

        if (name == "Price") {
          price = e?.target?.value ? e?.target?.value : 0;
          quantity = data.body[bodyIndex].Quantity.form.value
            ? data.body[bodyIndex].Quantity.form.value
            : 0;
        } else if (name == "Quantity") {
          quantity = e?.target?.value ? e?.target?.value : 0;
          price = data.body[bodyIndex].Price.form.value
            ? data.body[bodyIndex].Price.form.value
            : 0;
        } else if (name == "Tax") {
          tax = e?.target?.value ? e?.target?.value : 0;
          price = data.body[bodyIndex].Price.form.value
            ? data.body[bodyIndex].Price.form.value
            : 0;
          quantity = data.body[bodyIndex].Quantity.form.value
            ? data.body[bodyIndex].Quantity.form.value
            : 0;
        }

        tax_amount =
          parseFloat(price) * parseFloat(quantity) * (parseFloat(tax) / 100);
        total =
          parseFloat(price) * parseFloat(quantity) + parseFloat(tax_amount);

        data.body[bodyIndex]["Tax amount"].form.value = tax_amount?.toFixed(3);
        data.body[bodyIndex].Total.form.value = total?.toFixed(3);

        let subtotal = 0;
        let total_tax = 0;
        let discount = data.foot[2].Total.form.value
          ? data.foot[2].Total.form.value
          : 0;
        let delivery = data.foot[3].Total.form.value
          ? data.foot[3].Total.form.value
          : 0;
        let grand_total = 0;

        data?.body?.map((value, index) => {
          subtotal =
            parseFloat(subtotal ? subtotal : 0) +
            parseFloat(
              value?.Price?.form?.value ? value?.Price?.form?.value : 0
            ) *
              parseFloat(
                value?.Quantity?.form?.value ? value?.Quantity?.form?.value : 0
              );

          total_tax =
            parseFloat(total_tax ? total_tax : 0) +
            parseFloat(
              value?.["Tax amount"]?.form?.value
                ? value?.["Tax amount"]?.form?.value
                : 0
            );
        });

        grand_total =
          parseFloat(subtotal) +
          parseFloat(total_tax) +
          parseFloat(delivery) -
          parseFloat(discount);

        data.foot[0].Total.form.value = subtotal?.toFixed(3);
        data.foot[1].Total.form.value = total_tax?.toFixed(3);
        data.foot[4].Total.form.value = grand_total?.toFixed(3);

        form[index].options[ind].form = data;
        setForm([...form]);
      }
    },
    footChange: (e, FootIndex, name) => {
      let data = { ...form[index].options[ind].form };

      if (e?.target?.value > 0 || e?.target?.value == "") {
        data.foot[FootIndex][name].form.value = e?.target?.value;

        let subtotal = 0;
        let total_tax = 0;
        let discount = data.foot[2].Total.form.value
          ? data.foot[2].Total.form.value
          : 0;
        let delivery = data.foot[3].Total.form.value
          ? data.foot[3].Total.form.value
          : 0;

        data?.body?.map((value, index) => {
          subtotal =
            parseFloat(subtotal ? subtotal : 0) +
            parseFloat(
              value?.Price?.form?.value ? value?.Price?.form?.value : 0
            ) *
              parseFloat(
                value?.Quantity?.form?.value ? value?.Quantity?.form?.value : 0
              );

          total_tax =
            parseFloat(total_tax ? total_tax : 0) +
            parseFloat(
              value?.["Tax amount"]?.form?.value
                ? value?.["Tax amount"]?.form?.value
                : 0
            );
        });

        let grand_total =
          parseFloat(subtotal) +
          parseFloat(total_tax) +
          parseFloat(delivery) -
          parseFloat(discount);

        data.foot[4].Total.form.value = grand_total?.toFixed(3);

        form[index].options[ind].form = data;
        setForm([...form]);
      }
    },
  };

  const handleAdd = () => {
    if (form[index].options[ind].form?.type == "selecttable") {
      handleItem?.add();
    }
  };

  const handleRemove = (bodyIndex) => {
    if (form[index].options[ind].form?.type == "selecttable") {
      handleItem?.remove(bodyIndex);
    }
  };

  const handleSearch = async (e, bodyIndex, name) => {
    let data = { ...form[index].options[ind].form };

    const select = {
      api: data?.body?.[bodyIndex]?.[name]?.form?.api,
      search: e,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data.body[bodyIndex][name].form.options = options;

      form[index].options[ind].form = data;
      setForm([...form]);
    }
  };

  const handleSelect = (e, bodyIndex, name) => {
    if (form[index].options[ind].form?.type == "selecttable") {
      handleItem?.select(e, bodyIndex, name);
    }
  };

  const handleChange = (e, bodyIndex, name) => {
    if (form[index].options[ind].form?.type == "selecttable") {
      handleItem?.change(e, bodyIndex, name);
    }
  };

  const handleFootChange = (e, FootIndex, name) => {
    if (form[index].options[ind].form?.type == "selecttable") {
      handleItem?.footChange(e, FootIndex, name);
    }
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            {form[index].options[ind].form?.head?.map((head, headIndex) => {
              return (
                <td
                  className={
                    headIndex ===
                    form[index].options[ind].form?.head?.length - 1
                      ? "p-4 text-center border text-black"
                      : form[index].options[ind].form?.class
                      ? form[index].options[ind].form?.class
                      : "p-4 border text-black"
                  }
                >
                  {head?.name}
                </td>
              );
            })}
            <td className="border p-4 text-black" />
          </tr>
        </thead>
        <tbody>
          {form[index].options[ind].form?.body?.map((body, bodyIndex) => {
            return (
              <tr>
                <td className="border p-4 text-center">{bodyIndex + 1}</td>
                {form[index].options[ind].form?.head?.map((head, headIndex) => {
                  let tableBodyData = body?.[head?.name];

                  ref.current[
                    tableBodyData?.form?.name?.concat(
                      bodyIndex + "" + headIndex
                    )
                  ] = createRef();

                  return Object?.keys(body)?.includes(head?.name) ? (
                    <React.Fragment>
                      <td
                        className={
                          headIndex ===
                          head?.form?.form[index].options[ind].form?.head
                            ?.length -
                            1
                            ? "p-4 text-center border"
                            : head?.className
                            ? head?.className
                            : "p-4 border"
                        }
                      >
                        {tableBodyData?.type == "select" ? (
                          <div className="">
                            <Select
                              ref={
                                ref.current[
                                  tableBodyData?.form?.name?.concat(
                                    bodyIndex + "" + headIndex
                                  )
                                ]
                              }
                              key={bodyIndex?.toString()?.concat(headIndex)}
                              id={bodyIndex?.toString()?.concat(headIndex)}
                              name={tableBodyData?.form?.name}
                              mode={tableBodyData?.form?.mode}
                              allowClear={tableBodyData?.form?.allowClear}
                              showSearch={tableBodyData?.form?.showSearch}
                              filterOption={tableBodyData?.form?.filterOption}
                              placeholder={tableBodyData?.form?.placeholder}
                              className={
                                tableBodyData?.form?.className
                                  ? tableBodyData?.form?.className
                                  : "w-full"
                              }
                              value={tableBodyData?.form?.value}
                              options={tableBodyData?.form?.options}
                              onSearch={(e) =>
                                handleSearch(e, bodyIndex, head?.name)
                              }
                              onChange={(e) =>
                                handleSelect(e, bodyIndex, head?.name)
                              }
                            />
                            <Error
                              id={tableBodyData?.form?.name?.concat(
                                bodyIndex + "" + headIndex
                              )}
                            />
                          </div>
                        ) : tableBodyData?.type == "input" ? (
                          <div className="">
                            <Input
                              ref={
                                ref.current[
                                  tableBodyData?.form?.name?.concat(
                                    bodyIndex + "" + headIndex
                                  )
                                ]
                              }
                              key={bodyIndex?.toString()?.concat(headIndex)}
                              id={bodyIndex?.toString()?.concat(headIndex)}
                              name={tableBodyData?.form?.name}
                              type={tableBodyData?.form?.type}
                              placeholder={tableBodyData?.form?.placeholder}
                              value={tableBodyData?.form?.value}
                              disabled={tableBodyData?.form?.disabled}
                              autoComplete={false}
                              onChange={(e) =>
                                handleChange(e, bodyIndex, head?.name)
                              }
                            />
                            <Error
                              id={tableBodyData?.form?.name?.concat(
                                bodyIndex + "" + headIndex
                              )}
                            />
                          </div>
                        ) : tableBodyData?.type == "text" ? (
                          <div className="">{tableBodyData?.form?.value}</div>
                        ) : (
                          "-"
                        )}
                      </td>
                    </React.Fragment>
                  ) : (
                    <td className="p-4">-</td>
                  );
                })}
                <td className="border p-4 text-center">
                  <button
                    type="button"
                    className={
                      bodyIndex == 0
                        ? "text-red-800 text-lg cursor-not-allowed"
                        : "text-red-500 text-lg cursor-pointer hover:text-red-800"
                    }
                    onClick={() => handleRemove(bodyIndex)}
                    disabled={bodyIndex === 0}
                  >
                    <DeleteOutlined />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {form[index].options[ind].form?.foot?.map((foot, footIndex) => {
            return (
              <tr>
                <td className="border p-4" />
                {form[index].options[ind].form?.head?.map((head, headIndex) => {
                  let tableFootData = foot?.[head?.name];

                  return Object?.keys(foot)?.includes(head?.name) ? (
                    <td
                      className={
                        headIndex ===
                        head?.form?.form[index].options[ind].form?.head
                          ?.length -
                          1
                          ? "p-4 text-center border"
                          : head?.className
                          ? head?.className
                          : "p-4 border "
                      }
                      colSpan={tableFootData?.span}
                    >
                      {tableFootData?.type == "text" ? (
                        <div className={tableFootData?.form?.className}>
                          {tableFootData?.form?.value}
                        </div>
                      ) : tableFootData?.type == "input" ? (
                        <div className="">
                          <Input
                            key={footIndex?.toString()?.concat(headIndex)}
                            id={footIndex?.toString()?.concat(headIndex)}
                            name={tableFootData?.form?.name}
                            type={tableFootData?.form?.type}
                            placeholder={tableFootData?.form?.placeholder}
                            value={tableFootData?.form?.value}
                            disabled={tableFootData?.form?.disabled}
                            autoComplete={false}
                            onChange={(e) =>
                              handleFootChange(e, footIndex, head?.name)
                            }
                          />
                          <Error
                            id={tableFootData?.form?.name?.concat(
                              footIndex + "" + headIndex
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  ) : null;
                })}
                <td className="border p-4" />
              </tr>
            );
          })}
        </tfoot>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Formtable;
