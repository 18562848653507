import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Select,
  Space,
} from "antd";
import React, { createRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Error from "../Error";
import Required from "../Required";
import DragDrop from "../Dropzone";
import AddButton from "../Buttons/add";
import PlusButton from "../Buttons/plus";
import Authform from "../Authform";
import Popup from "../Modal";
import { select_search } from "../../Controllers/Global";
import dayjs from "dayjs";
import Formtable from "../Formtable";

const Form = (props) => {
  let navigate = useNavigate();

  const formdata = props?.formdata;

  const [form, setForm] = useState(props?.form);
  const [popup, setPopup] = useState({});
  const focus = useRef([]);

  const getFormdata = () => {
    let data = [];
    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        data?.push({ [val?.form?.name]: val?.form?.value });
      });
    });

    return data;
  };

  const onchange = (e, index, ind) => {
    let data = [...form];
    data[index].options[ind].form.value = e?.target?.value;
    setForm(data);
  };

  const onselect = (e, index, ind) => {
    let data = [...form];

    data[index].options[ind].form.value = e;

    console.log(data[index].options[ind].form.formtable, "table");
    console.log(data, "data");

    if (data[index].options[ind].form.formtable) {
      clearformtable(index, ind);
    }

    if (data[index]?.options[ind]?.show?.check) {
      data?.map((value, k) => {
        value?.options?.map((val, i) => {
          if (val?.show?.name == data[index].options[ind].form.name) {
            data[k].options[i].show.status = e ? true : false;
            data[k].options[i].form.value = "";
          }
        });
      });
    }

    setForm(data);
    formdata(getFormdata());
  };

  const clearformtable = (index, ind) => {
    let data = [...form];

    data?.map((value, index) => {
      if (value?.options?.[0]?.type === "formtable") {
        let newRow = JSON.parse(
          JSON.stringify(value.options[0]?.form?.body[0])
        );

        newRow.Description.form.value = null;
        newRow.Unit.form.value = "-";
        newRow.Quantity.form.value = "";
        newRow.Quantity.form.disabled = true;
        newRow.Price.form.value = "";
        newRow.Price.form.disabled = true;
        newRow.Tax.form.value = "0%";
        newRow["Tax amount"].form.value = 0;
        newRow.Total.form.value = 0;

        if (value.options[0]?.form?.foot?.length > 0) {
          value.options[0]?.form?.foot?.map((foot, footIndex) => {
            value.options[0]?.form?.head?.map((head, headIndex) => {
              if (Object?.keys(foot)?.includes(head?.name)) {
                let tableFootData = foot?.[head?.name];

                if (tableFootData?.type && tableFootData.form.name) {
                  data[index].options[0].form.foot[footIndex][
                    head?.name
                  ].form.value = 0;
                }
              }
            });
          });
        }

        data[index].options[0].form.body = [newRow];
      }
    });
  };

  const handleDate = (e, index, ind) => {
    console.log(e?.$d);

    let data = [...form];
    data[index].options[ind].form.value = e?.$d;
    setForm(data);
  };

  const onsearch = async (e, index, ind) => {
    let data = [...form];

    const select = {
      api: data[index].options[ind].form.api,
      search: e,
    };
    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options[ind].form.options = options;
      setForm(data);
    }
  };

  const oncheckbox = (e, index, ind) => {};

  const validate = (data) => {
    let total = data?.length;
    let count = 0;

    let validated = {};

    data?.map((value, index) => {
      let ind = value?.index + "" + value?.ind;

      if (!value?.value && value?.required) {
        document.getElementById(
          value?.name?.concat(ind)
        ).textContent = `${value?.name} is required`;
        if (index == count) {
          focus.current[value?.name?.concat(ind)].current.focus();
        }
      } else if (value?.value && value?.required) {
        document.getElementById(value?.name?.concat(ind)).textContent = "";
        validated[value?.name] = value?.value;
        count++;
      } else if (value?.type == "selecttable") {
        let details = [];
        let body_length = value?.body?.length;
        let body_count = 0;

        value?.body?.map((body, bodyIndex) => {
          let head_data = {};
          let head_length = value?.head?.length;
          let head_count = 0;

          value?.head?.map((head, headIndex) => {
            let tableBodyData = body?.[head?.name];

            console.log(tableBodyData, "tableBodyData");

            if (Object?.keys(body)?.includes(head?.name)) {
              if (
                !tableBodyData?.form?.value &&
                tableBodyData?.form?.required
              ) {
                document.getElementById(
                  tableBodyData?.form?.name?.concat(bodyIndex + "" + headIndex)
                ).textContent = `${tableBodyData?.form?.name} is required`;

                if (
                  index == count &&
                  body_count == bodyIndex &&
                  headIndex == head_count
                ) {
                  focus.current[
                    tableBodyData?.form?.name?.concat(
                      bodyIndex + "" + headIndex
                    )
                  ].current.focus();
                }
              } else if (
                tableBodyData?.form?.value &&
                tableBodyData?.form?.required
              ) {
                document.getElementById(
                  tableBodyData?.form?.name?.concat(bodyIndex + "" + headIndex)
                ).textContent = "";
                head_data[tableBodyData?.form?.name] =
                  tableBodyData?.form?.value;
                head_count++;
              } else {
                head_data[tableBodyData?.form?.name] =
                  tableBodyData?.form?.value;
                head_count++;
              }
            }

            if (head_length === head_count) {
              details?.push(head_data);
              body_count++;
            }
          });
        });

        if (value?.foot?.length > 0) {
          let foot_data = {};
          let foot_length = value?.head?.length;
          let foot_count = 0;

          value?.foot?.map((foot, footIndex) => {
            value?.head?.map((head, headIndex) => {
              if (Object?.keys(foot)?.includes(head?.name)) {
                console.log("tableFootData");

                let tableFootData = foot?.[head?.name];

                console.log(tableFootData, "tableFootData");

                if (tableFootData?.type && tableFootData.form.name) {
                  validated[tableFootData.form.name] = tableFootData.form.value;
                }
              }
            });
          });
        }

        if (body_length === body_count) {
          validated["details"] = details;
          count++;
        }
      } else {
        validated[value?.name] = value?.value;
        count++;
      }
    });

    if (total === count) {
      return { status: true, data: validated };
    } else {
      return { status: false, data: validated };
    }
  };

  const handleSave = (e) => {
    e?.preventDefault();

    console.log(form);

    let data = [];
    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        data?.push({ index: index, ind: ind, ...val?.form });
      });
    });

    let validated = validate(data);
    formdata(validated);
  };

  const handleCancel = (e) => {
    e?.preventDefault();

    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        if (form[index].options[ind].type == "add") {
          form[index].options[ind].form.value = null;
        } else if (
          form[index].options[ind].type == "input" ||
          form[index].options[ind].type == "upload"
        ) {
          form[index].options[ind].form.value = "";
        }
      });
    });

    setForm([...form]);
  };

  return (
    <form
      className={
        props?.width
          ? `bg-white p-4 rounded-md p-8 ${props?.width} mx-auto`
          : `bg-white p-4 rounded-md p-8 w-[50%] mx-auto`
      }
      onReset={handleCancel}
      onSubmit={handleSave}
    >
      {form?.map((value, index) => {
        return (
          <div>
            <Popup popup={popup} setPopup={setPopup} />
            <div
              className={index > 0 ? "text-medium py-3" : "text-medium pb-3"}
            >
              <div>{value?.name}</div>
            </div>
            <div className="border text-sm text-medium text-gray-500  rounded-md p-4">
              {value?.options?.map((val, ind) => {
                focus.current[val?.form?.name?.concat(index + "" + ind)] =
                  createRef();
                return (
                  <div>
                    {val?.show?.status != false && (
                      <div className="pb-2 flex justify-between text-medium text-gray-500 text-sm">
                        <div>
                          <label htmlFor={index?.toString()?.concat(ind)}>
                            {val?.name} {val?.form?.required && <Required />}
                          </label>
                        </div>
                        <div>
                          {val?.name === "Barcode" && val?.form?.value && (
                            <div
                              onClick={() =>
                                navigate(
                                  `/app/inventory/barcode/${val?.form?.value}`
                                )
                              }
                            >
                              Print
                            </div>
                          )}
                        </div>
                        {val?.view?.path && (
                          <div>
                            <button
                              type="button"
                              onClick={() => navigate(val?.view?.path)}
                            >
                              <u className="cursor-pointer text-[#07B553] hover:text-[#14532d]">
                                {val?.view?.name}
                              </u>
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {/* components */}
                    {val?.show?.status != false && (
                      <div>
                        {val?.type === "input" ? (
                          <div className="pb-4">
                            <Input
                              key={index?.toString()?.concat(ind)}
                              id={index?.toString()?.concat(ind)}
                              ref={
                                focus.current[
                                  val?.form?.name?.concat(index + "" + ind)
                                ]
                              }
                              name={val?.form?.name}
                              type={val?.form?.type}
                              className={
                                val?.form?.class
                                  ? val?.form?.class
                                  : "text-regular"
                              }
                              placeholder={val?.form?.placeholder}
                              onChange={(e) => onchange(e, index, ind)}
                              value={val?.form?.value}
                              autoComplete="off"
                            />
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "select" ? (
                          <div className="pb-4">
                            <Select
                              key={index?.toString()?.concat(ind)}
                              id={index?.toString()?.concat(ind)}
                              ref={
                                focus.current[
                                  val?.form?.name?.concat(index + "" + ind)
                                ]
                              }
                              name={val?.form?.name}
                              mode={val?.form?.mode}
                              allowClear={val?.form?.allowClear}
                              showSearch={val?.form?.showSearch}
                              filterOption={val?.form?.filterOption}
                              onSearch={(e) => onsearch(e, index, ind)}
                              className={
                                val?.form?.class
                                  ? val?.form?.class
                                  : "text-regular w-full"
                              }
                              placeholder={val?.form?.placeholder}
                              value={val?.form?.value}
                              options={val?.form?.options}
                              onChange={(e) => onselect(e, index, ind)}
                            />
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "add" ? (
                          <div className="pb-4">
                            <div className="flex">
                              <Select
                                key={index?.toString()?.concat(ind)}
                                id={index?.toString()?.concat(ind)}
                                ref={
                                  focus.current[
                                    val?.form?.name?.concat(index + "" + ind)
                                  ]
                                }
                                name={val?.form?.name}
                                mode={val?.form?.mode}
                                filterOption={val?.form?.filterOption}
                                showSearch={val?.form?.showSearch}
                                onSearch={(e) => onsearch(e, index, ind)}
                                allowClear={val?.form?.allowClear}
                                className={
                                  val?.form?.class
                                    ? val?.form?.class
                                    : "text-regular w-full"
                                }
                                placeholder={val?.form?.placeholder}
                                value={val?.form?.value}
                                options={val?.form?.options}
                                onChange={(e) => onselect(e, index, ind)}
                              />
                              <div className="pl-3">
                                <PlusButton
                                  onClick={() => navigate(val?.form?.path)}
                                />
                              </div>
                            </div>
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "date" ? (
                          <div className="pb-4">
                            <DatePicker
                              key={index?.toString()?.concat(ind)}
                              id={index?.toString()?.concat(ind)}
                              ref={
                                focus.current[
                                  val?.form?.name?.concat(index + "" + ind)
                                ]
                              }
                              className="text-regular w-full"
                              name={val?.form?.name}
                              placeholder={val?.form?.placeholder}
                              value={
                                val?.form?.value && dayjs(val?.form?.value)
                              }
                              onChange={(e) => handleDate(e, index, ind)}
                            />
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "checkbox" ? (
                          <div className="pb-4 ">
                            <Checkbox
                              key={index?.toString()?.concat(ind)}
                              id={index?.toString()?.concat(ind)}
                              ref={
                                focus.current[
                                  val?.form?.name?.concat(index + "" + ind)
                                ]
                              }
                              name={val?.form?.placeholder}
                              checked={val?.form?.checked}
                              disabled={val?.form?.disabled}
                              className={
                                val?.form?.class
                                  ? val?.form?.class
                                  : "text-medium text-gray-500 "
                              }
                              onChange={(e) => oncheckbox(e, index, ind)}
                            >
                              <span></span>
                              {val?.form?.placeholder}
                            </Checkbox>
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "upload" ? (
                          <div className="pb-4">
                            <DragDrop
                              ref={focus}
                              form={form}
                              setForm={setForm}
                              index={index}
                              ind={ind}
                            />
                          </div>
                        ) : val?.type === "component" ? (
                          <div className="pb-4">{val?.form}</div>
                        ) : val?.type === "formtable" ? (
                          <div className="pb-4">
                            <Formtable
                              ref={focus}
                              form={form}
                              setForm={setForm}
                              index={index}
                              ind={ind}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {/* components */}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="py-6">
        <Button
          className="mr-2 text-medium form-back-button"
          htmlType="button"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <div className="float-end ">
          <Button
            className="mr-2 text-medium form-cancel-button"
            htmlType="reset"
          >
            Cancel
          </Button>
          <Button
            className="text-medium form-save-button"
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
