import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

const ProfileButton = () => {
  let navigate = useNavigate();
  return (
    <div
      className="bg-white py-2 px-4 w-full  cursor-pointer"
      onClick={() => navigate("/app/dashboard")}
    >
      <div className="flex justify-between">
        {/* <div>
          <div className="flex">
            <img
              src="https://via.placeholder.com/150"
              className="w-[35px] h-[35px] rounded-md object-cover"
            />
            <div className="px-2 text-medium text-sm">
              <div>Jcob Jones</div>
              <div className="text-xs text-regular text-gray-500">Admin</div>
            </div>
          </div>
        </div> */}
        <div className="flex">
          <div>
            <Avatar
              name={"Alex Jhon"}
              size={35}
              textSizeRatio={1.75}
              className="rounded-md"
            />
          </div>
          <div className="pl-2">
            <div className="text-medium text-sm">{"Alex Jhon"}</div>
            <div className="text-xs text-regular text-gray-500">{"Admin"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileButton;
