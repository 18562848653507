import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import Table from "../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../Data/Tables/Billing/tables";
import headers from "../../../Data/Tables/Billing/headers";
import billing from "../../../Data/billing.json";
import ActionButton from "../../../Componets/Actionbutton";

const BillingTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async (tableData) => {
    try {
      let data = [];

      if (tableData) {
        tableData?.map((value, index) => {
          data?.push({
            Number: value?.number,
            Date: value?.date,
            Customer: value?.customer,
            Created: value?.created,
            Amount: value?.amount,
            Payment: value?.payment,
            Amount: value?.amount,
            Action: <ActionButton edit={`/app/billing/add`} delete="/" />,
          });
        });
      } else {
        billing?.map((value, index) => {
          data?.push({
            Number: value?.number,
            Date: value?.date,
            Customer: value?.customer,
            Created: value?.created,
            Amount: value?.amount,
            Payment: value?.payment,
            Amount: value?.amount,
            Action: <ActionButton edit={`/app/billing/add`} delete="/" />,
          });
        });
      }

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...billing];

    let search = data?.search?.toLowerCase();
    let sort = data?.sort;
    let payment = data?.payment;
    let customers = data?.customers;
    let date = data?.date;

    if (search) {
      tableData = tableData.filter((item) =>
        item?.number?.toLowerCase().includes(search)
      );
    }

    if (sort) {
      tableData = tableData.sort((a, b) => {
        if (sort === "1") {
          return a.amount - b.amount;
        } else if (sort === "2") {
          return b.amount - a.amount;
        }
        return 0;
      });
    }

    if (payment) {
      tableData = tableData.filter((item) => item?.payment?.includes(payment));
    }

    if (customers) {
      tableData = tableData.filter((item) => item?.customer == customers);
    }

    if (date && date.start && date.end) {
      const startDate = new Date(date.start);
      const endDate = new Date(date.end);

      tableData = tableData.filter((item) => {
        const itemDate = parseDate(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default BillingTable;
