import React from "react";

const Error = (props) => {
  const id = props?.id;
  const name = props?.name;

  return <div id={id} name={name} className="pt-0.5 text-xs text-red-500" />;
};

export default Error;
