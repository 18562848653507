const headers = {
  name: "Purchase sent",
  navigate: {
    path: [
      {
        name: "Sent",
        path: "/app/purchases/sent",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
