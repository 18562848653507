import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Sales/Order/tables";
import headers from "../../../../Data/Tables/Sales/Order/headers";
import sales from "../../../../Data/sales.json";
import ActionButton from "../../../../Componets/Buttons/action";

const SalesOrderTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
      let data = [];

      sales?.map((value, index) => {
        data?.push({
          path: "/app/sales/order/view",
          Sale: value?.number,
          Customer: value?.supplier,
          Date: value?.date,
          Due: value?.due,
          Received: value?.received,
          Paid: value?.paid,
          Amount: value?.amount,
          Action: <ActionButton edit={`/`} delete="/" />,
        });
      });

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default SalesOrderTable;
