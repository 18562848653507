const tables = [
  {
    name: "Name",
  },
  {
    name: "Batch",
  },
  {
    name: "Expiry",
  },
  {
    name: "Branch",
  },
  {
    name: "Action",
  },
];

export default tables;
