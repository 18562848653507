import { DatePicker, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import AddButton from "../Buttons/add";
import Error from "../Error";
import { select_search } from "../../Controllers/Global";
import { DeleteOutlined } from "@ant-design/icons";
import products from "../../Data/products.json";
import inventory from "../../Data/inventory.json";
import { toast } from "react-toastify";

const Saletable = React.forwardRef((props, ref) => {
  const [table, setTable] = useState([
    {
      description: null,
      purchase_price: "",
      quantity: "",
      free: "",
      tax: 0,
      total: 0,
    },
  ]);

  const [productOptions, setProductOptions] = useState(
    products?.map((value) => ({ value: value?.name, label: value?.name }))
  );

  const handleDescription = (e, index) => {
    let data = [...table];
    data[index].description = e;

    setTable(data);
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    console.log(e, e);

    if (value >= 0) {
      let data = [...table];

      let data_purchase_price = data[index]?.purchase_price
        ? data[index]?.purchase_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_free = data[index]?.free ? data[index]?.free : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;

      let purchase_price =
        name === "purchase_price" ? value : data_purchase_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let free = name === "free" ? value : data_free;
      let tax = name === "tax" ? value : data_tax;

      let tax_amount = tax
        ? parseFloat(purchase_price) *
          parseFloat(quantity) *
          (parseFloat(tax) / 100)
        : 0;

      let total =
        parseFloat(purchase_price) * parseFloat(quantity) +
        parseFloat(tax_amount);

      let per_unit =
        parseFloat(total) / (parseFloat(quantity) + parseFloat(free));

      console.log(value, "valuevalue");

      data[index][name] = value;
      data[index].tax = tax;
      data[index].tax_amount = tax;
      data[index].total = total ? total?.toFixed(3) : 0;
      data[index].per_unit = per_unit ? per_unit?.toFixed(3) : 0;
      data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;

      console.log(purchase_price, quantity);
      setTable(data);
    }
  };

  const handleRemove = (index) => {
    let data = [...table];
    data?.splice(index, 1);

    setTable(data);
  };

  const handleAdd = (product) => {
    setTable((prevProducts) => [
      ...prevProducts,
      {
        description: product || "",
        purchase_price: "",
        quantity: "",
        free: "",
        tax: 0,
        total: 0,
      },
    ]);
  };

  const handleBarcodeAdd = (barcode) => {
    const selected_inventory = inventory?.find(
      (item) => String(item.barcode) === barcode.trim()
    );

    const selected_product = products.find(
      (item) => item.id === selected_inventory?.id
    );

    const product = products.find(
      (item) => String(item.barcode) === barcode.trim()
    );

    console.log(barcode, "barcode");

    let data = {};

    if (product?.name) {
      table?.map((value, index) => {
        if (value?.description === product?.name) {
          data = { ...value, index: index };
        }
      });

      if (data?.value?.description) {
        let quantity = parseFloat(data?.quantity) + 1;
        handleTotal(quantity, data?.index, "quantity");
      } else {
        console.log(product?.name, "wdwddwdd");
        handleAdd(product?.name);
        // handleAdd(selected_product?.name, selected_inventory?.sale_price);
      }
    } else {
      toast?.error("Product not found");
    }
  };

  const barcodeRef = useRef("");

  useEffect(() => {
    let barcode = "";
    const handleKeyDown = (event) => {
      if (event.key.length === 1) {
        barcode += event.key;
      }

      if (event.key === "Enter") {
        handleBarcodeAdd(barcode.trim());
        barcode = "";
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const subtotal = (
    table?.reduce(
      (acc, currentValue) => parseFloat(acc) + parseFloat(currentValue?.total),
      0
    ) -
    table?.reduce(
      (acc, currentValue) =>
        parseFloat(acc) + parseFloat(currentValue?.tax_amount),
      0
    )
  )?.toFixed(3);

  const totalTaxAmount = table
    ?.reduce(
      (acc, currentValue) =>
        parseFloat(acc) + parseFloat(currentValue?.tax_amount),
      0
    )
    ?.toFixed(3);

  const totalAmount = table
    ?.reduce(
      (acc, currentValue) => parseFloat(acc) + parseFloat(currentValue?.total),
      0
    )
    ?.toFixed(3);

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Price</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Tax</td>
            <td className="border p-4 text-black">Tax Amount</td>
            <td className="border p-4 text-black">Total</td>
            <td className="border p-4 text-black">Delete</td>
          </tr>
        </thead>
        <tbody>
          {table?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    <Select
                      className="w-full"
                      name="description"
                      placeholder="Description"
                      allowClear={true}
                      showSearch={true}
                      filterOption={true}
                      value={value?.description}
                      options={productOptions}
                      onChange={(e) => handleDescription(e, index)}
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="purchase_price"
                      placeholder="Purchase price"
                      value={value?.purchase_price}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="quantity"
                      placeholder="Quantity"
                      value={value?.quantity}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  {/* <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      placeholder="Free"
                      name="free"
                      value={value?.free}
                      autoComplete="off"
                      onChange={(e) => handleTotal(e, index)}
                    />
                  </td> */}
                  <td className="border p-4 text-center">
                    <Select
                      className="w-full"
                      name="tax"
                      placeholder="Tax"
                      options={[
                        {
                          value: 0,
                          label: "0%",
                        },
                        {
                          value: 5,
                          label: "5%",
                        },
                      ]}
                      value={value?.tax}
                      onChange={(e) => handleTotal(e, index, "tax")}
                    />
                  </td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.tax_amount)?.toFixed(3)}
                  </td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.total)?.toFixed(3)}
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className={
                        index == 0
                          ? "text-lg text-red-900 cursor-not-allowed"
                          : "text-lg text-red-500 cursor-pointer hover:text-red-800"
                      }
                      disabled={index == 0}
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
                {/* <tr>
                  <td className="border p-4 text-center"></td>
                  <td className="border p-4"></td>
                  <td className="border p-4">
                    <Input
                      className="w-full"
                      placeholder="Sale price"
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4">
                    <DatePicker className="w-full" placeholder="Manufacture" />
                  </td>
                  <td className="border p-4">
                    <DatePicker className="w-full" placeholder="Expiry" />
                  </td>
                  <td className="border p-4">
                    {value?.tax_amount ? value?.tax_amount : "0.000"}
                  </td>
                  <td className="border p-4">
                    {value?.per_unit ? value?.per_unit : "0.000"}
                  </td>
                  <td className="border p-4"></td>
                </tr> */}
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={5}></td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4" colSpan={2}>
              {subtotal > 0 ? subtotal : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={5}></td>
            <td className="border p-4">Tax&nbsp;amount</td>
            <td className="border p-4" colSpan={2}>
              {totalTaxAmount > 0 ? totalTaxAmount : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={5}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {totalAmount > 0 ? totalAmount : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Saletable;
