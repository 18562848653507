import React from "react";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import { Outlet, useLocation, Navigate } from "react-router-dom";

const Layout = () => {
  let location = useLocation();
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-hidden ml-64">
        <Topbar />
        <div className="flex-1 overflow-y-auto">
          <div className="mt-16">
            {location?.pathname === "/app" ? (
              <Navigate to="/app/dashboard" />
            ) : (
              <Outlet />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
