import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Products/Products/tables";
import headers from "../../../../Data/Tables/Products/Products/headers";
import TableImage from "../../../../Componets/Tableimage";
import ValueTag from "../../../../Componets/Valuetag/index.js";
import Status from "../../../../Componets/Status/status";
import ActionButton from "../../../../Componets/Actionbutton";
import products from "../../../../Data/products.json";
import units from "../../../../Data/units.json";
import categories from "../../../../Data/category.json";
import brands from "../../../../Data/brands.json";

const ProductsTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  let tag = (stock, notify) => {
    let data = "Stocked";

    if (stock == 0) {
      data = "Out of Stock";
    } else if (stock <= notify) {
      data = "Low";
    }

    return data;
  };

  let color = (stock, notify) => {
    let data = "green";

    if (stock == 0) {
      data = "red";
    } else if (stock <= notify) {
      data = "orange";
    }

    return data;
  };

  const get_unit = (id) => {
    let unit = "";
    units?.map((value, index) => {
      if (id == value?.id) {
        unit = value?.name;
      }
    });
    return unit;
  };

  const get_brand = (id) => {
    let brand = "";
    brands?.map((value, index) => {
      if (id == value?.id) {
        brand = value?.name;
      }
    });

    console.log(brands, brand, id, "wdwdwdwd");
    return brand;
  };

  const get_category = (id) => {
    let category = "";
    categories?.map((value, index) => {
      if (id == value?.id) {
        category = value?.name;
      }
    });
    return category;
  };

  const fetchData = async (tableData) => {
    try {
      console.log(products, "products");

      let data = [];

      if (tableData) {
        tableData?.map((value, index) => {
          data?.push({
            Name: <TableImage image={value?.image} name={value?.name} />,
            Stock: (
              <ValueTag
                value={value?.stock}
                tag={tag?.(value?.stock, value?.notify)}
                color={color?.(value?.stock, value?.notify)}
              />
            ),
            Unit: get_unit(value?.units),
            Pack: value?.strips ? value?.strips + "*" + value?.tablets : 1,
            Category: get_category(value?.category),
            Brand: get_brand(value?.brands),
            Status: <Status status={value?.status} />,
            Action: <ActionButton edit={`/app/products/add`} delete="/" />,
          });
        });
      } else {
        products?.map((value, index) => {
          data?.push({
            Name: <TableImage image={value?.image} name={value?.name} />,
            Stock: (
              <ValueTag
                value={value?.stock}
                tag={tag?.(value?.stock, value?.notify)}
                color={color?.(value?.stock, value?.notify)}
              />
            ),
            Unit: get_unit(value?.units),
            Pack: value?.strips ? value?.strips + "*" + value?.tablets : 1,
            Category: get_category(value?.category),
            Brand: get_brand(value?.brands),
            Status: <Status status={value?.status} />,
            Action: <ActionButton edit={`/app/products/add`} delete="/" />,
          });
        });
      }

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...products];

    let search = data?.search?.toLowerCase();
    let sort = data?.sort;
    let unit = data?.units;
    let category = data?.category;
    let brands = data?.brands;

    if (search) {
      tableData = tableData.filter((item) =>
        item?.name?.toLowerCase().includes(search)
      );
    }

    if (sort) {
      tableData = tableData.sort((a, b) => {
        if (sort === "1") {
          return a.name.localeCompare(b.name);
        } else if (sort === "2") {
          return b.name.localeCompare(a.name);
        } else if (sort === "3") {
          return a.stock - b.stock;
        } else if (sort === "4") {
          return b.stock - a.stock;
        }
        return 0;
      });
    }

    if (unit) {
      tableData = tableData.filter((item) => get_unit(item.units) === unit);
    }

    if (category) {
      tableData = tableData.filter(
        (item) => get_category(item.category) === category
      );
    }

    if (brands) {
      tableData = tableData.filter((item) => get_brand(item.brands) === brands);
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default ProductsTable;
