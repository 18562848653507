import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Sales/Refund/tables";
import headers from "../../../../Data/Tables/Sales/Refund/headers";
import sale_refund from "../../../../Data/sales_refund.json";
import ActionButton from "../../../../Componets/Buttons/action";

const SalesRefundTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
      let data = [];

      sale_refund?.map((value, index) => {
        data?.push({
          Number: value?.return,
          Sale: value?.number,
          Date: value?.date,
          Amount: value?.amount,
          Payment: value?.payment,
          Action: <ActionButton edit={`/`} delete="/" />,
        });
      });

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default SalesRefundTable;
