import React from "react";
import Authform from "../../../Componets/Authform";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Signin = () => {
  let navigate = useNavigate();

  const auth = {
    name: "Sign In",
    button: "Login",
    link: {
      name: "Signup",
      path: "/signup",
      message: "Don't have an account ? ",
    },
    options: [
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "username",
          placeholder: "Username",
          icon: <UserOutlined className="text-gray-500" />,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "password",
        form: {
          type: "text",
          name: "password",
          placeholder: "Password",
          show: false,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  };

  const formdata = (data) => {
    console.log(data);
    navigate("/app/dashboard");
  };

  return (
    <div className="bg-gray-100">
      <Authform auth={auth} formdata={formdata} />
    </div>
  );
};

export default Signin;
