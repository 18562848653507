const forms = [
  {
    name: "Return",
    options: [
      {
        name: "Sale",
        type: "select",
        form: {
          name: "sale",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Select sale",
          value: null,
          options: null,
          required: true,
        },
      },
      {
        name: "Sales Return",
        type: "input",
        form: {
          type: "number",
          name: "amount",
          placeholder: "Sales return number",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "name",
          placeholder: "Sale order date",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Items",
    options: [
      {
        name: "Details",
        type: "formtable",
        form: {
          name: "details",
          type: "selecttable",
          options: null,
          head: [
            { name: "Description" },
            { name: "Price" },
            { name: "Quantity" },
            // { name: "Unit" },
            { name: "Total quantity" },
            { name: "Tax" },
            { name: "Tax amount" },
            { name: "Total" },
          ],
          body: [
            {
              Description: {
                type: "select",
                form: {
                  name: "description",
                  allowClear: true,
                  showSearch: true,
                  filterOption: true,
                  className: "w-[400px]",
                  placeholder: "Select Description",
                  value: null,
                  options: null,
                  api: "get-all-items",
                  required: true,
                },
              },
              Quantity: {
                type: "input",
                form: {
                  name: "quantity",
                  type: "number",
                  placeholder: "Description quantity",
                  value: "",
                  disabled: true,
                  required: true,
                },
              },
              Price: {
                type: "text",
                form: {
                  name: "price",
                  type: "number",
                  placeholder: "Description price",
                  value: "",
                  disabled: true,
                  required: true,
                },
              },
              Unit: {
                type: "text",
                form: {
                  name: "unit",
                  value: "-",
                },
              },
              Tax: {
                type: "text",
                form: {
                  name: "tax",
                  type: "number",
                  placeholder: "Description tax",
                  value: "",
                  disabled: true,
                  required: false,
                },
              },
              "Tax amount": {
                type: "text",
                form: {
                  name: "tax_amount",
                  value: 0,
                },
              },
              Total: {
                type: "text",
                form: {
                  name: "total",
                  value: 0,
                },
              },
            },
          ],
          foot: [
            {
              Description: "",
              "Total quantity": "",
              Quantity: "",
              Price: "",
              "Tax amount": {
                type: "text",
                span: 2,
                form: {
                  value: "Subtotal",
                },
              },
              Total: {
                type: "text",
                form: {
                  name: "subtotal",
                  value: 0,
                },
              },
            },
            {
              Description: "",
              "Total quantity": "",
              Quantity: "",
              Price: "",
              "Tax amount": {
                type: "text",
                span: 2,
                form: {
                  value: "Tax",
                },
              },
              Total: {
                type: "text",
                form: {
                  name: "tax",
                  value: 0,
                },
              },
            },
            {
              Description: "",
              "Total quantity": "",
              Quantity: "",
              Price: "",
              "Tax amount": {
                type: "text",
                span: 2,
                form: {
                  value: "Discount",
                },
              },
              Total: {
                type: "input",
                form: {
                  name: "discount",
                  type: "number",
                  placeholder: "Discount",
                  value: "",
                },
              },
            },
            {
              Description: "",
              "Total quantity": "",
              Quantity: "",
              Price: "",
              "Tax amount": {
                type: "text",
                span: 2,
                form: {
                  value: "Delivery",
                },
              },
              Total: {
                type: "input",
                form: {
                  name: "delivery",
                  type: "number",
                  placeholder: "Delivery",
                  value: "",
                },
              },
            },
            {
              Description: "",
              "Total quantity": "",
              Quantity: "",
              Price: "",
              "Tax amount": {
                type: "text",
                span: 2,
                form: {
                  className: "text-black text-base",
                  value: "Total",
                },
              },
              Total: {
                type: "text",
                form: {
                  name: "total",
                  className: "text-black text-base",
                  value: 0,
                },
              },
            },
          ],
        },
      },
    ],
  },
];

export default forms;
