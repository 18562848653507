import { Button, Form, Input, Modal } from "antd";
import React, { createRef, useRef, useState } from "react";
import Error from "../Error";
import Required from "../Required";
import { useNavigate } from "react-router-dom";

const Popup = (props) => {
  let isModal = props?.popup?.status;

  const focus = useRef([]);

  const onchange = (e, index, ind) => {
    let data = [...props?.popup?.data];
    data[index].options[ind].form.value = e?.target?.value;
    props.setPopup({
      status: true,
      data: data,
    });
  };

  const validate = (data) => {
    let total = data?.length;
    let count = 0;

    let validated = [];
    data?.map((value, index) => {
      let ind = value?.index + "" + value?.ind;

      if (!value?.value && value?.required) {
        let data = [...props?.popup?.data];
        data[value?.index].options[
          value?.ind
        ].form.error = `${value?.name} is required`;
        props.setPopup({
          status: true,
          data: data,
        });

        if (index == count) {
          focus.current[value?.name?.concat(ind)].current.focus();
        }
      } else if (value?.value && value?.required) {
        let data = [...props?.popup?.data];
        data[value?.index].options[value?.ind].form.error = "";
        props.setPopup({
          status: true,
          data: data,
        });

        validated?.push({ [value?.name]: value?.value });
        count++;
      } else {
        validated?.push({ [value?.name]: value?.value });
        count++;
      }
    });

    if (total == count) {
      return { status: true, data: validated };
    } else {
      return { status: false, data: validated };
    }
  };

  const handleSave = (e) => {
    e?.preventDefault();

    let data = [];
    props?.popup?.data?.map((value, index) => {
      value?.options?.map((val, ind) => {
        data?.push({ index: index, ind: ind, ...val?.form });
      });
    });

    let validated = validate(data);
    if (validated?.status == true) {
      console.log("dwdwddwdwd", validated);
    }
  };

  const handleCancel = (e) => {
    e?.preventDefault();

    props.popup.status = false;
    props?.setPopup({});
    console.log(props?.props);
  };

  return (
    <Modal open={isModal} onCancel={handleCancel} footer={null}>
      <div>
        {props?.popup?.data?.map((value, index) => {
          return (
            <div>
              <div
                className={index > 0 ? "text-medium py-3" : "text-medium pb-3"}
              >
                {value?.name}
              </div>
              <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
                {value?.options?.map((val, ind) => {
                  focus.current[val?.form?.name?.concat(index + "" + ind)] =
                    createRef();
                  return (
                    <div>
                      {val?.show?.status != false && (
                        <div className="pb-2 text-medium text-gray-500 text-sm">
                          <label
                            htmlFor={index
                              ?.toString()
                              ?.concat(index + "" + ind)}
                          >
                            {val?.name} {val?.form?.required && <Required />}
                          </label>
                        </div>
                      )}

                      {/* input */}
                      {val?.show?.status != false && (
                        <div>
                          {val?.type === "input" ? (
                            <div className="pb-4">
                              <Input
                                key={index?.toString()?.concat(ind)}
                                id={index?.toString()?.concat(ind)}
                                ref={
                                  focus.current[
                                    val?.form?.name?.concat(index + "" + ind)
                                  ]
                                }
                                name={val?.form?.name}
                                type={val?.form?.type}
                                className={
                                  val?.form?.class
                                    ? val?.form?.class
                                    : "text-regular"
                                }
                                placeholder={val?.form?.placeholder}
                                onChange={(e) => onchange(e, index, ind)}
                                value={val?.form?.value}
                                autoComplete="off"
                              />
                              <div
                                id={val?.form?.name?.concat(index + "" + ind)}
                                className="pt-0.5 text-xs text-red-500"
                              >
                                {val?.form?.error}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div className="py-6">
          <div className="float-end ">
            <Button className="mr-2 text-medium" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="text-medium" type="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
