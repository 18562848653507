import { PrinterOutlined } from "@ant-design/icons";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const InvoicePage = () => {
  let componentRef = useRef();

  return (
    <div>
      {/* page */}
      <div className="flex justify-center">
        <div className="w-[8.27in]  text-right py-4">
          <ReactToPrint
            trigger={() => (
              <div className="">
                <PrinterOutlined className="text-2xl" />
              </div>
            )}
            content={() => componentRef}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="page-a4" ref={(el) => (componentRef = el)}>
          {/*letter header */}
          <div className="text-indigo-900 text-[0.65rem] text-center">
            <div className="text-center">
              <div className="text-medium text-xl">ABC Pharmacy LLC</div>
              <div className="text-medium">Pharmacy</div>
            </div>
          </div>
          <div className="text-indigo-900 text-[0.65rem] text-center pt-4">
            <span>C.R.&nbsp;:&nbsp;1233305,&nbsp;</span>
            <span>P.O&nbsp;Box&nbsp;:&nbsp;5678,&nbsp;</span>
            <span>P.O&nbsp;Code&nbsp;:&nbsp;342,&nbsp;</span>
            <span>Fax&nbsp;:&nbsp;2132435,&nbsp;</span>
            <span>Tel&nbsp;:&nbsp;22876756,&nbsp;</span>
            <span>Email&nbsp;:&nbsp;info@aljazeera.com,&nbsp;</span>
            <span>F.Code&nbsp;:&nbsp;8756432,&nbsp;</span>
            <div>Al-Ansab,&nbsp;Muscat,&nbsp;Sultanate&nbsp;Of&nbsp;Oman</div>
            <div>VATIN&nbsp;:&nbsp;51234876543754</div>
          </div>
          {/* heading */}
          <hr className="my-4 border-t-indigo-900 border-black" />
          <div className="text-medium text-xl text-center">Invoice</div>
          <div className="text-xs pb-6">
            <div className="flex justify-between">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className="font-semibold text-sm">Royster divas</td>
                    </tr>
                    <tr>
                      <td>18th November Street</td>
                    </tr>
                    <tr>
                      <td>Azaiba, Muscat</td>
                    </tr>
                    <tr>
                      <td>Sultanate of Oman</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Invoice No</td>
                      <td className="px-2">:</td>
                      <td className="text-right">#INV-1001</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td className="px-2">:</td>
                      <td className="text-right"> 20/07/2024</td>
                    </tr>
                    <tr>
                      <td>Due</td>
                      <td className="px-2">:</td>
                      <td className="text-right"> 05/08/2024</td>
                    </tr>
                    <tr>
                      <td>Reference</td>
                      <td className="px-2">:</td>
                      <td className="text-right">Jhon Nick</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td className="px-2">:</td>
                      <td className="text-right">784323456</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* table */}
          <div className="">
            <table className="w-full">
              <thead className="bg-black">
                <tr className="text-sm">
                  <th className="font-normal  text-white  text-left py-2 pl-3">
                    S.No
                  </th>
                  <th className="font-normal text-white text-left p-2">
                    Description
                  </th>
                  <th className="font-normal text-white text-center p-2">
                    Units
                  </th>
                  <th className="font-normal text-white text-center p-2">
                    Pack
                  </th>
                  <th className="font-normal text-white text-center p-2">
                    Price
                  </th>
                  <th className="font-normal text-white text-center p-2">
                    Quantity
                  </th>
                  <th className="font-normal text-white text-center">Tax</th>
                  <th className="font-normal text-white text-center">
                    Tax amount
                  </th>
                  <th className="font-normal text-white text-right pr-2">
                    Total
                  </th>
                </tr>
              </thead>
              {/* table body */}
              <tbody className="text-sm">
                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">1</td>
                  <td className="py-1 text-left">
                    <div className="">LAMICTAL LIQUI-TABS 5MG (Batch-1001)</div>
                  </td>
                  <td className="py-1 text-center">PACK </td>
                  <td className="py-1 text-center">3*10 </td>
                  <td className="py-1 text-center">1.95</td>
                  <td className="py-1 text-center">2</td>
                  <td className="py-1 text-center">5%</td>
                  <td className="py-1 text-center">1.95</td>
                  <td className="text-right pr-2">4.095</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">2</td>
                  <td className="py-1 text-left">
                    <div className="">
                      ENGERIX VACCINE PAD10MCG/VIAL (Batch-1003)
                    </div>
                  </td>
                  <td className="py-1 text-center">ML </td>
                  <td className="py-1 text-center">- </td>
                  <td className="py-1 text-center">3.65</td>
                  <td className="py-1 text-center">4</td>
                  <td className="py-1 text-center">5%</td>
                  <td className="py-1 text-center">0.93</td>
                  <td className="text-right pr-2">15.33</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">3</td>
                  <td className="py-1 text-left">
                    <div className="">ZESTRIL TABLETS 5MG (Batch-1005)</div>
                  </td>
                  <td className="py-1 text-center">PACK </td>
                  <td className="py-1 text-center">2*14</td>
                  <td className="py-1 text-center">1.35</td>
                  <td className="py-1 text-center">3</td>
                  <td className="py-1 text-center">5%</td>
                  <td className="py-1 text-center">0.202</td>
                  <td className="text-right pr-2">4.252</td>
                </tr>
              </tbody>
              {/* table foot */}
              <tfoot className="text-sm ">
                <tr className="">
                  <th colSpan={7}></th>
                  <th className="font-normal pt-1 text-right p-2">Total</th>
                  <td className="font-normal text-right p-2">18.837</td>
                </tr>
                <tr>
                  <th colSpan={7}></th>
                  <th className="font-normal text-right p-2">VAT (5%)</th>
                  <td className="font-normal text-right p-2">4.83</td>
                </tr>
                <tr>
                  <th className="" colSpan={7}></th>
                  <th className="font-normal text-right text-white bg-black p-2">
                    Grand&nbsp;Total&nbsp;(OMR)
                  </th>
                  <td className="font-normal text-right text-white bg-black p-2">
                    23.677
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* note */}
          <div className="pt-4 text-sm">
            <div className="">
              {/* <div className="font-semibold">Note&nbsp;&nbsp;</div>
              <li>
                Please feel free to contact us for any questions or concerns.
              </li> */}
            </div>
            <div className="pt-4">
              {/* <div className="font-semibold">
                Terms & Conditions&nbsp;&nbsp;
              </div>
              <li>Quote validity for 30 days.</li> */}
            </div>
          </div>
          <div className="">
            <div
              className="page-footer mb-[1.5cm]"
              style={{ padding: " 1.6cm" }}
            >
              <div className="flex justify-between px-[0.5cm]">
                <div className="text-[0.65rem]">
                  Customer's Signature (Name/Designation/Co. Seal)
                </div>
                <div className="text-[0.65rem]">For New Pharmacy LLC</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePage;
