import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Inventory/Batch/tables";
import headers from "../../../../Data/Tables/Inventory/Batch/headers";
import inventory from "../../../../Data/inventory.json";
import products from "../../../../Data/products.json";
import ValueTag from "../../../../Componets/Valuetag";
import ActionButton from "../../../../Componets/Actionbutton";

const InventoryBatchTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const fetchData = async (tableData) => {
    try {
      let data = [];

      if (tableData) {
        tableData?.map((value, index) => {
          data?.push({
            Name: get_product(value?.product),
            Batch: value?.batch_number,
            Stock: value?.stock,
            Strips: value?.strips,
            Tablets: value?.tablets,
            Purchase: value?.purchase_price,
            Sale: value?.sale_price,
            Tax: value?.tax,
            Expiry: value?.expiry_date,
            Action: (
              <ActionButton
                edit={`/app/inventory/edit/${value?.id}`}
                delete="/"
              />
            ),
          });
        });
      } else {
        inventory?.map((value, index) => {
          data?.push({
            Name: get_product(value?.product),
            Batch: value?.batch_number,
            Stock: value?.stock,
            Strips: value?.strips,
            Tablets: value?.tablets,
            Purchase: value?.purchase_price,
            Sale: value?.sale_price,
            Tax: value?.tax,
            Expiry: value?.expiry_date,
            Action: (
              <ActionButton
                edit={`/app/inventory/edit/${value?.id}`}
                delete="/"
              />
            ),
          });
        });
      }

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...inventory];

    let search = data?.search?.toLowerCase();
    let sort = data?.sort;

    if (search) {
      tableData = tableData.filter((item) =>
        get_product(item?.product)?.toLowerCase().includes(search)
      );
    }

    if (sort) {
      tableData = tableData.sort((a, b) => {
        if (sort === "1") {
          return get_product(a.product).localeCompare(get_product(b.product));
        } else if (sort === "2") {
          return get_product(b.product).localeCompare(get_product(a.product));
        } else if (sort === "3") {
          return parseDate(a.expiry_date) - parseDate(b.expiry_date);
        } else if (sort === "4") {
          return parseDate(b.expiry_date) - parseDate(a.expiry_date);
        } else if (sort === "5") {
          return a.purchase_price - b.purchase_price;
        } else if (sort === "6") {
          return b.purchase_price - a.purchase_price;
        } else if (sort === "7") {
          return a.sale_price - b.sale_price;
        } else if (sort === "8") {
          return b.sale_price - a.sale_price;
        }
        return 0;
      });
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default InventoryBatchTable;
