import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./App/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <div className="text-regular">
        <ToastContainer />
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
