import React from "react";

const PurchaseIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g transform="matrix(0.93,0,0,0.93,17.919999999999987,17.919999999999987)">
        <path
          d="M320 0v128h128z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M320 160c-17.632 0-32-14.368-32-32V0H96C78.368 0 64 14.368 64 32v448c0 17.664 14.368 32 32 32h320c17.664 0 32-14.336 32-32V160H320zM176 97.984V80c0-8.832 7.168-16 16-16s16 7.168 16 16v18.016c10.016 2.272 19.36 6.496 27.072 12.768 6.88 5.568 7.904 15.648 2.304 22.496-5.6 6.88-15.68 7.872-22.496 2.304C208.832 130.688 200.704 128 192 128c-17.344 0-32 10.976-32 24s14.656 24 32 24c35.296 0 64 25.12 64 56 0 26.016-20.48 47.744-48 54.016V304c0 8.832-7.168 16-16 16s-16-7.168-16-16v-18.016c-10.016-2.272-19.36-6.496-27.072-12.768-6.88-5.6-7.904-15.68-2.304-22.528 5.6-6.912 15.648-7.872 22.496-2.304C175.168 253.312 183.296 256 192 256c17.344 0 32-10.976 32-24s-14.656-24-32-24c-35.296 0-64-25.12-64-56 0-26.016 20.48-47.744 48-54.016zM368 448H144c-8.832 0-16-7.168-16-16s7.168-16 16-16h224c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0-64H144c-8.832 0-16-7.168-16-16s7.168-16 16-16h224c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0-64h-64c-8.832 0-16-7.168-16-16s7.168-16 16-16h64c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0-64h-64c-8.832 0-16-7.168-16-16s7.168-16 16-16h64c8.832 0 16 7.136 16 16 0 8.832-7.168 16-16 16z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default PurchaseIcon;
