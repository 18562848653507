import React from "react";

const Signout = () => {
  return (
    <div>
      <div>Signout</div>
    </div>
  );
};

export default Signout;
