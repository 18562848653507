import React from "react";

const SupplierIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 35 35"
    >
      <g transform="matrix(1.1000000000000014,0,0,1.1000000000000014,-1.750000023841885,-1.7499999046325811)">
        <path
          d="M21.92 5.503h-19c-.56 0-1 .44-1 1v12c0 .55.44 1 1 1h19c.55 0 1-.45 1-1v-12c0-.56-.45-1-1-1zM1.91 24.503c0 .55.45 1 1 1h1.08c.42-2.09 2.14-3.73 4.28-4H2.92c-.36 0-.69-.06-1.01-.18z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M30.85 25.503h1.24c.55 0 1-.45 1-1v-8.76c0-1.57-3.83-5.24-5.93-5.24h-2.24v8c0 1.65-1.35 3-3 3H9.57c2.14.27 3.86 1.91 4.28 4h7.14c.46-2.3 2.49-4.05 4.93-4.05 2.43 0 4.47 1.75 4.93 4.05zm-4.29-12.54c5.12-.25 4.91 5.04 4.91 5.04h-4.91z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
        <circle
          cx="25.916"
          cy="26.477"
          r="3.02"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></circle>
        <circle
          cx="8.916"
          cy="26.477"
          r="3.02"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></circle>
      </g>
    </svg>
  );
};

export default SupplierIcon;
