import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Products/Units/tables";
import headers from "../../../../Data/Tables/Products/Units/headers";
import units from "../../../../Data/units.json";
import Status from "../../../../Componets/Status/status";
import ActionButton from "../../../../Componets/Actionbutton";

const ProductunitsTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async (value) => {
    try {
      let data = [];

      if (value) {
        value?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton edit={`/app/products/units/add`} delete="/" />
            ),
          });
        });
      } else {
        units?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton edit={`/app/products/units/add`} delete="/" />
            ),
          });
        });
      }

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    let tableData = [...units];

    let search = data?.search?.toLowerCase();
    let sort = data?.sort;
    let status = data?.status;

    if (search) {
      tableData = tableData.filter((item) =>
        item?.name?.toLowerCase().includes(search)
      );
    }

    if (sort) {
      tableData = tableData.sort((a, b) => {
        if (sort === "1") {
          return a.name.localeCompare(b.name);
        } else if (sort === "2") {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    }

    if (status || status == 0) {
      tableData = tableData.filter((item) => item.status === status);
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default ProductunitsTable;
