import React from "react";

const ThermalInvoice = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={styles.invoice}>
      <div style={styles.header}>
        <h2>ABC Pharmacy LLC</h2>
        <p>Building 3006 , Way 75</p>
        <p>Muttrah , Muscat</p>
        <p>Sultanate Of Oman</p>
      </div>
      <div style={styles.details}>
        <p>Date: {new Date().toLocaleDateString()}</p>
        <p>Invoice #: 1016</p>
      </div>
      <div style={styles.items}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th className="p-1" style={styles.th}>
                Item
              </th>
              <th className="p-1" style={styles.th}>
                QTY
              </th>
              <th className="p-1" style={styles.th}>
                STR
              </th>
              <th className="p-1" style={styles.th}>
                TAB
              </th>
              <th className="p-1" style={styles.th}>
                PRC
              </th>
              <th className="px-1" style={styles.th}>
                TAX
              </th>
              <th className="p-1" style={styles.th}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item, index) => (
              <tr key={index}>
                <td className="p-1" style={styles.td}>
                  {item?.name}
                </td>
                <td className="p-1" style={styles.td}>
                  &nbsp;{item?.quantity}
                </td>
                <td className="p-1" style={styles.td}>
                  &nbsp;{item?.strips ? item?.strips : "-"}
                </td>
                <td className="p-1" style={styles.td}>
                  &nbsp;{item?.tablets ? item?.tablets : "-"}
                </td>
                <td className="p-1" style={styles.td}>
                  {item?.price.toFixed(3)}
                </td>
                <td className="p-1" style={styles.td}>
                  &nbsp;{item?.tax.toFixed(3)}
                </td>
                <td className="p-1" style={styles.td}>
                  &nbsp;{item?.price.toFixed(3)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={styles.total}>
        <h3>Total: OMR{props.total.toFixed(3)}</h3>
      </div>
    </div>
  );
});

const styles = {
  invoice: {
    width: "80mm", // Typical width for thermal printers
    fontFamily: "monospace",
    fontSize: "12px",
    padding: "10px",
    // border: "1px solid #000",
  },
  header: {
    textAlign: "center",
    marginBottom: "10px",
  },
  details: {
    marginBottom: "10px",
  },
  items: {
    marginBottom: "10px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    borderBottom: "1px solid #000",
    textAlign: "left",
  },
  td: {
    padding: "5px 0",
  },
  total: {
    textAlign: "right",
    fontWeight: "bold",
  },
};

export default ThermalInvoice;
