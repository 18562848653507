import customers from "../../customers.json";

const headers = {
  name: "Billing",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      value: null,
      options: [
        { value: "1", label: "Price (Low)" },
        { value: "2", label: "Price (High)" },
      ],
    },
    {
      type: "filter",
      name: "payment",
      placeholder: "Payment",
      value: null,
      options: [
        { value: "Cash", label: "Cash" },
        { value: "Card", label: "Card" },
        { value: "Bank Transfer", label: "Bank Transfer" },
      ],
    },
    {
      type: "filter",
      name: "customers",
      placeholder: "Customers",
      value: null,
      options: customers?.map((value, index) => {
        return { value: value?.name, label: value?.name };
      }),
    },
    {
      type: "range",
      name: "date",
      value: {
        start: "",
        end: "",
      },
    },
    {
      type: "add",
      path: "/app/billing/add",
    },
  ],
  navigate: {
    path: [],
    total: [
      { value: "1", label: "Total" },
      { value: "1", label: "Active" },
      { value: "1", label: "Inactive" },
      { value: "1", label: "Paid" },
      { value: "1", label: "Partial" },
      { value: "1", label: "Unpaid" },
    ],
  },
};

export default headers;
