import { Button, Input, Select, Space } from "antd";
import React, { createRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Error from "../Error";
import Required from "../Required";
import DragDrop from "../../Dropzone";
import {
  EyeFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const Authform = (props) => {
  let navigate = useNavigate();

  const [forms, setForm] = useState(props?.auth);
  const formdata = props?.formdata;
  const focus = useRef([]);

  const handleHideShow = (ind) => {
    let data = { ...forms };
    data.options[ind].form.show = !data.options[ind].form.show;
    setForm(data);
  };

  const onchange = (e, ind) => {
    let data = { ...forms };
    data.options[ind].form.value = e?.target?.value;
    setForm(data);
  };

  const onselect = (e, index, ind) => {
    let data = [...forms];
  };

  const validate = (data) => {
    let total = data?.length;
    let count = 0;

    let validated = [];
    data?.map((value, index) => {
      if (!value?.value && value?.required) {
        document.getElementById(
          value?.name?.concat(index)
        ).textContent = `${value?.name} is required`;
        if (index == count) {
          focus.current[value?.name?.concat(index)].current.focus();
        }
      } else if (value?.value && value?.required) {
        document.getElementById(value?.name?.concat(index)).textContent = "";
        validated?.push({ [value?.name]: value?.value });
        count++;
      } else {
        validated?.push({ [value?.name]: value?.value });
        count++;
      }
    });

    if (total == count) {
      return validated;
    }
  };

  const handleSave = (e) => {
    e?.preventDefault();

    let data = [];
    forms?.options?.map((value, index) => {
      data?.push({ index: index, ...value?.form });
    });

    let validated = validate(data);
    formdata(validated);
  };

  const handleCancel = (e) => {
    e?.preventDefault();
    navigate(-1);
  };

  return (
    <div className="flex items-center min-h-screen w-full">
      <form
        className="bg-white p-4 border rounded-lg p-8 w-[20%] mx-auto"
        onReset={handleCancel}
        onSubmit={handleSave}
      >
        <div className="text-gray-500">
          <div className="text-[#07B553] text-semi-bold text-2xl pb-3">
            {forms?.name}
          </div>
          <div className="text-sm text-medium text-gray-500 rounded-md">
            {forms?.options?.map((val, ind) => {
              focus.current[val?.form?.name?.concat(ind)] = createRef();
              return (
                <div>
                  {val?.type === "input" ? (
                    <div className="pb-4">
                      <Input
                        key={ind}
                        id={ind}
                        ref={focus.current[val?.form?.name?.concat(ind)]}
                        size={val?.form?.size}
                        name={val?.form?.name}
                        type={val?.form?.type}
                        className={
                          val?.form?.class ? val?.form?.class : "text-regular"
                        }
                        placeholder={val?.form?.placeholder}
                        onChange={(e) => onchange(e, ind)}
                        value={val?.form?.value}
                        autoComplete="off"
                        suffix={val.form.icon}
                      />
                      <Error id={val?.form?.name?.concat(ind)} />
                    </div>
                  ) : val?.type === "password" ? (
                    <div className="pb-4">
                      <Input
                        key={ind}
                        id={ind}
                        ref={focus.current[val?.form?.name?.concat(ind)]}
                        size={val?.form?.size}
                        name={val?.form?.name}
                        type={val?.form?.show ? "text" : "password"}
                        className={
                          val?.form?.class ? val?.form?.class : "text-regular"
                        }
                        placeholder={val?.form?.placeholder}
                        onChange={(e) => onchange(e, ind)}
                        value={val?.form?.value}
                        autoComplete="off"
                        suffix={
                          val?.form?.show ? (
                            <EyeOutlined
                              className="text-gray-500"
                              onClick={() => handleHideShow(ind)}
                            />
                          ) : (
                            <EyeInvisibleOutlined
                              className="text-gray-500"
                              onClick={() => handleHideShow(ind)}
                            />
                          )
                        }
                      />
                      <Error id={val?.form?.name?.concat(ind)} />
                    </div>
                  ) : val?.type === "inputgroup" ? (
                    <div>
                      <Space.Compact>
                        <Input className="w-[30%]" />
                        <Input className="w-[70%]" />
                      </Space.Compact>
                    </div>
                  ) : val?.type === "select" ? (
                    <div className="pb-4">
                      <Select
                        key={ind}
                        name={val?.form?.name}
                        showSearch={val?.form?.allowClear}
                        allowClear={val?.form?.allowClear}
                        filterOption={val?.form?.filterOption}
                        size={val?.form?.size}
                        className={
                          val?.form?.class ? val?.form?.class : "w-full"
                        }
                        placeholder={val?.form?.placeholder}
                        options={val?.form?.options}
                        suffixIcon={val?.form?.suffixIcon}
                      />
                      <Error id={val?.form?.name?.concat(ind)} />
                    </div>
                  ) : val?.type === "upload" ? (
                    <div className="pb-4">
                      <DragDrop {...val?.form} />
                      <Error id={val?.form?.name?.concat(ind)} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="">
          <Button
            size="large"
            type="primary"
            className="text-medium w-full bg-[#07B553] text-white"
            htmlType="submit"
          >
            {forms?.button}
          </Button>
        </div>
        <div className="pt-3 text-medium text-gray-500 text-center">
          <span>{forms?.link?.message}</span>
          &nbsp;
          <span
            className="text-[#07B553] cursor-pointer underline"
            onClick={() => navigate(forms?.link?.path)}
          >
            {forms?.link?.name}
          </span>
        </div>
      </form>
    </div>
  );
};

export default Authform;
