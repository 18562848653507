const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "name",
          placeholder: "Expense date",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Category",
        type: "add",
        form: {
          name: "category",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Expense category",
          value: null,
          options: [],
          required: true,
          path: "/app/settings/expense/categories/add",
        },
      },
    ],
  },
  {
    name: "Description",
    options: [
      {
        name: "Description",
        type: "input",
        form: {
          type: "text",
          name: "description",
          placeholder: "Expense description",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Amount",
    options: [
      {
        name: "Amount",
        type: "input",
        form: {
          type: "number",
          name: "area",
          placeholder: "Expense amount",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
];

export default forms;
