import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ThermalInvoice from "./ThermalInvoice";
import Barcode from "react-barcode";
import products from "../../../../Data/products.json";
import inventory from "../../../../Data/inventory.json";
import { useParams } from "react-router-dom";
import { PrinterOutlined } from "@ant-design/icons";

const BarcodePrinter = () => {
  const a4ref = useRef();

  const invoiceRef = useRef();

  const barcodeRef = useRef();

  const handleA4Print = useReactToPrint({
    content: () => a4ref.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 50px;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          font-family: 'monospace';
          background-color: white;
          margin: 0;
          padding: 0;
        }
      }
    `,
  });

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    pageStyle: `
      @page {
        size: 80mm auto;
        margin: 0mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          font-family: 'monospace';
        }
      }
    `,
  });

  const handleBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const items = [
    {
      name: "Item 1",
      quantity: 1,
      price: 9.99,
    },
    { name: "Item 2", quantity: 2, price: 19.99 },
  ];

  const total = items.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  const getproduct = (id) => {
    let product = {};

    products?.map((value, index) => {
      if (id == value?.id) {
        product = value;
      }
    });

    console.log(product);

    return product;
  };

  const { id } = useParams();

  return (
    <div>
      {/* <div>
        <div ref={a4ref}>
          <div>
            <div className="text-medium text-center text-2xl pb-4">
              Products
            </div>
            {products?.map((value, index) => {
              return (
                <div className="pb-4 text-center flex flex-col items-center">
                  <div>
                    <img src={value?.image} width={60} height={60} />
                  </div>
                  <div>{value?.name}</div>
                  <div>
                    <Barcode value={value?.barcode} height={50} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button className="pt-1" onClick={handleA4Print}>
          Print Product
        </button>
      </div> */}

      {/* <div>
        <ThermalInvoice ref={invoiceRef} items={items} total={total} />
        <button className="pt-1" onClick={handlePrint}>
          Print Invoice
        </button>
      </div> */}

      <div className="py-4">
        <Barcode ref={barcodeRef} value={id} />
        <button className="pt-4" onClick={handleBarcode}>
          Print Barcode <PrinterOutlined />
        </button>
      </div>
    </div>
  );
};

export default BarcodePrinter;
