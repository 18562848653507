const tables = [
  {
    name: "Return",
  },
  {
    name: "Purchase",
  },
  {
    name: "Supplier",
  },
  {
    name: "Date",
  },
  {
    name: "Due",
  },
  {
    name: "Branch",
  },
  {
    name: "Action",
  },
];

export default tables;
