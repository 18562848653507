import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const DragDrop = (props) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const thumbs = files.map((file) => (
    <div key={file.name}>
      {/* {file?.type?.includes("image") && (
        <div className="pt-1">
          <img
            src={file.preview}
            className="w-[100px] h-[100px]"
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      )} */}
      <div className="text-xs text-gray-400 text-regular">
        {file.path} - {file.size} bytes
      </div>
    </div>
  ));

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
    });

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li className="py-2" key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li className="py-2" key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code} className="text-xs text-red-500">
            {e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section className="">
      <div className="">
        <div
          {...getRootProps({
            className:
              "dropzone text-center text-regular text-gray-400 cursor-pointer border rounded-lg p-6",
          })}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(Only *.jpeg and *.png images will be accepted)</em>
        </div>
      </div>

      <aside>{thumbs ? thumbs : "----"}</aside>
    </section>
  );
};

export default DragDrop;
