import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const PlusButton = (props) => {
  return (
    <Button
      className="text-bold flex items-center justify-center p-2 add-button"
      {...props}
    >
      <PlusOutlined />
    </Button>
  );
};

export default PlusButton;
