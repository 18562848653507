const forms = [
  {
    name: "Date",
    options: [
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "date",
          placeholder: "Request date",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Branches",
    options: [
      {
        name: "Branch",
        type: "add",
        form: {
          mode: "multiple",
          name: "inventory",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Transfer branch",
          value: null,
          options: [],
          required: false,
          path: "/app/settings/branches/add",
        },
      },
    ],
  },
  {
    name: "Items",
    options: [
      {
        name: "Items",
        type: "component",
      },
    ],
  },
];

export default forms;
