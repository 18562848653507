import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const select_search = async (select) => {
  try {
    const api = select?.api;

    const data = {
      search: select?.search,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/${api}`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { select_search };
