import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Settings/Roles/tables";
import headers from "../../../../Data/Tables/Settings/Roles/headers";
import roles from "../../../../Data/roles.json";
import ActionButton from "../../../../Componets/Actionbutton";

const RolesTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
      let data = [];

      roles?.map((value, index) => {
        data?.push({
          Name: value?.name,
          "Created By": value?.created,
          Action: <ActionButton edit={`/`} delete="/" />,
        });
      });

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default RolesTable;
