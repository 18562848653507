import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import products from "../../Data/products.json";
import inventory from "../../Data/inventory.json";
import BarcodeButton from "../../Componets/Buttons/barcode";
import { toast } from "react-toastify";
import tables from "../../Data/Tables/Billing/tables";

const Pos = () => {
  let navigate = useNavigate();

  let quantityref = useRef([]);

  const redirect = () => {
    navigate("/app/products/add");
  };

  const get_product_image = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.image;
      }
    });

    return product;
  };

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const [inventoryList, setInventoryList] = useState([...inventory]);
  const [addedProduct, setAddedProduct] = useState([]);
  const [paymentType, setPaymentType] = useState([]);

  const search_product = (e) => {
    const searchValue = e?.target?.value?.toLowerCase();
    const searched_inventory = inventory.filter((item) =>
      get_product(item?.product)?.toLowerCase().includes(searchValue)
    );

    setInventoryList(searched_inventory);
  };

  const add_payment_type = (value) => {
    let data = [...paymentType];

    if (!data?.includes(value)) {
      data?.push(value);
    } else {
      data?.pop(value);
    }

    setPaymentType(data);
  };

  const add_product = (value) => {
    let index;

    setAddedProduct((prevProducts) => {
      let data = [...prevProducts];
      let ids = data.map((item) => item.id);

      if (!ids.includes(value?.batch_number)) {
        const selected_product = products?.find(
          (item) => item?.id == value?.product
        );

        let tax_amount = parseFloat(value?.sale_price) * (value?.tax / 100);
        let total = parseFloat(value?.sale_price) + tax_amount;

        quantityref.current[prevProducts.length] = createRef();

        data.push({
          id: value?.batch_number,
          name: get_product(value?.product) + " (" + value?.batch_number + " )",
          price: parseFloat(value?.sale_price)?.toFixed(3),
          quantity: 1,
          isStrips: selected_product?.strips > 0 ? true : 0,
          isTablet: selected_product?.tablets > 0 ? true : 0,
          tax_amount: parseFloat(tax_amount)?.toFixed(3),
          total: parseFloat(total)?.toFixed(3),
        });

        index = data?.length - 1;
      } else {
        let existingIndex = ids.indexOf(value?.batch_number);
        let price = parseFloat(data[existingIndex].price);
        let quantity = parseFloat(data[existingIndex].quantity) + 1;
        let tax = parseFloat(value?.tax);
        let tax_amount = price * quantity * (tax / 100);
        let total = price * quantity + tax_amount;

        data[existingIndex] = {
          ...data[existingIndex],
          quantity: quantity,
          tax_amount: parseFloat(tax_amount)?.toFixed(3),
          total: parseFloat(total)?.toFixed(3),
        };

        index = existingIndex;
      }

      return data;
    });

    // setTimeout(() => {
    //   if (quantityref.current[index]) {
    //     quantityref.current[index].current.focus();
    //   }
    // }, 200);
  };

  const calculate_price = (e, batch_number, index) => {
    if (e?.target?.value >= 0) {
      let data = [...addedProduct];

      const selected_inventory = inventory?.find(
        (item) => item?.batch_number == batch_number
      );
      const selected_product = products?.find(
        (item) => item?.id == selected_inventory?.product
      );

      let amount = 0;
      let quantity = data?.[index]?.quantity ? data?.[index]?.quantity : 0;
      let strips = data?.[index]?.strips ? data?.[index]?.strips : 0;
      let tablets = data?.[index]?.tablets ? data?.[index]?.tablets : 0;
      let price = selected_inventory?.sale_price
        ? selected_inventory?.sale_price
        : 0;
      let tax = selected_inventory?.tax ? selected_inventory?.tax : 0;
      let tax_amount = data?.[index]?.tax_amount
        ? data?.[index]?.tax_amount
        : 0;
      let total = data?.[index]?.total ? data?.[index]?.total : 0;

      if (e?.target?.name == "quantity") {
        quantity = e?.target?.value;
      } else if (e?.target?.name == "strips" && selected_product?.strips > 0) {
        strips = e?.target?.value;
      } else if (
        e?.target?.name == "tablets" &&
        selected_product?.tablets > 0
      ) {
        tablets = e?.target?.value;
      }

      if (quantity && !strips && !tablets) {
        price = parseFloat(price);
        amount = parseFloat(quantity) * parseFloat(price);
      } else if (!quantity && strips && !tablets) {
        price = parseFloat(price) / parseFloat(selected_product?.strips);
        amount = parseFloat(strips) * parseFloat(price);
      } else if (!quantity && !strips && tablets) {
        price =
          parseFloat(price) /
          (parseFloat(selected_product?.strips) *
            parseFloat(selected_product?.tablets));
        amount = parseFloat(tablets) * parseFloat(price);
      } else if (!quantity && strips && tablets) {
        let strip_price =
          parseFloat(price) / parseFloat(selected_product?.strips);
        let tablets_price =
          parseFloat(price) /
          (parseFloat(selected_product?.strips) *
            parseFloat(selected_product?.tablets));

        price = strip_price + tablets_price;
        amount =
          parseFloat(strips) * parseFloat(strip_price) +
          parseFloat(tablets) * parseFloat(tablets_price);
      } else if (quantity && !strips && tablets) {
        let quantity_price = parseFloat(price);
        let tablets_price =
          parseFloat(price) /
          (parseFloat(selected_product?.strips) *
            parseFloat(selected_product?.tablets));

        amount =
          parseFloat(quantity) * parseFloat(quantity_price) +
          parseFloat(tablets) * parseFloat(tablets_price);
        price = amount / (parseFloat(quantity) + parseFloat(tablets));
      } else if (quantity && strips && !tablets) {
        let quantity_price = parseFloat(price);
        let strip_price =
          parseFloat(price) / parseFloat(selected_product?.strips);

        amount =
          parseFloat(quantity) * parseFloat(quantity_price) +
          parseFloat(strips) * parseFloat(strip_price);
        price = amount / (parseFloat(quantity) + parseFloat(strips));
      } else if (quantity && strips && tablets) {
        let quantity_price = parseFloat(price);
        let strip_price =
          parseFloat(price) / parseFloat(selected_product?.strips);
        let tablets_price =
          parseFloat(price) /
          (parseFloat(selected_product?.strips) *
            parseFloat(selected_product?.tablets));

        amount =
          parseFloat(quantity) * parseFloat(quantity_price) +
          parseFloat(strips) * parseFloat(strip_price) +
          parseFloat(tablets) * parseFloat(tablets_price);
        price =
          amount /
          (parseFloat(quantity) + parseFloat(strips) + parseFloat(tablets));
      }

      tax_amount = parseFloat(amount) * (parseFloat(tax) / 100);
      total = parseFloat(amount) + parseFloat(tax_amount);

      data[index].quantity = quantity;
      data[index].strips = strips;
      data[index].tablets = tablets;
      data[index].price = price ? parseFloat(price)?.toFixed(3) : 0;
      data[index].tax_amount = tax_amount
        ? parseFloat(tax_amount)?.toFixed(3)
        : 0;
      data[index].total = total ? parseFloat(total)?.toFixed(3) : 0;

      setAddedProduct(data);
    }
  };

  const stockchange = (e, index) => {
    let value = e?.target?.value;

    if (e?.target?.name >= 0) {
      let data = [...addedProduct];

      if (e?.target?.name == "strips") {
        data[index][e?.target?.name] = value;
        let price = data[index][e?.target?.name];
      } else if (e?.target?.name == "strips") {
        data[index][e?.target?.name] = value;
        let price = data[index][e?.target?.name];
      }
    }
  };

  const delete_product = (index) => {
    let data = [...addedProduct];

    data?.splice(index, 1);
    setAddedProduct(data);
  };

  const handleBarcodeAdd = (barcode) => {
    const product = inventory.find((item) => item.barcode == barcode);

    if (product?.product) {
      add_product(product);
    } else {
      toast?.error("Product not found");
    }
  };

  useEffect(() => {
    let barcode = "";
    const handleKeyDown = (event) => {
      if (event.key.length === 1) {
        barcode += event.key;
      }

      if (event.key === "Enter") {
        handleBarcodeAdd(barcode.trim());
        barcode = "";
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div className="p-4">
        <div className="flex justify-between">
          {/* products */}
          <div className="bg-white w-[54%] rounded-md">
            <div className="p-4 flex justify-between">
              <div className="w-2/5">
                <Input
                  allowClear
                  className="search "
                  placeholder=" Search"
                  onChange={search_product}
                  prefix={
                    <span className="text-gray-500 text-medium">
                      <SearchOutlined />
                    </span>
                  }
                />
              </div>
              {/* <div>
                <BarcodeButton tabIndex={0} onKeyDown={handleKeyDown} />
              </div> */}
            </div>
            <div className="h-[60vh]" style={{ overflowX: "auto" }}>
              <div className="flex flex-wrap">
                {inventoryList?.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer p-4 w-[20%]"
                      onClick={() => add_product(value)}
                    >
                      <div className="hover:bg-gray-100 p-2">
                        <img
                          className="rounded-md w-full h-[125px]"
                          src={
                            get_product_image(value?.product)
                              ? get_product_image(value?.product)
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6xiTpb6Tyc-CTn4FJmXyNBuPze14R-qIJNIDHj2uQbidXRFY1Otr27ZQd69L5_drFaDY&usqp=CAU"
                          }
                        />
                      </div>
                      <div className="text-center pt-2">
                        <div>{get_product(value?.product)}</div>
                        <div className="text-sm text-gray-500">
                          {"" + value?.batch_number + " "}
                        </div>
                        <div className="text-sm text-gray-500">
                          {"EXP : " + value?.expiry_date}
                        </div>
                      </div>
                      <div className="text-center text-green-500 text-medium">
                        OMR {parseFloat?.(value?.sale_price)?.toFixed(3)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* checkout */}
          <div className="bg-white w-[44%] rounded-md">
            <div className="p-4 flex justify-center">
              <div className="p-1 text-semi-bold">Checkout</div>
            </div>
            <div className="h-[50vh]" style={{ overflowY: "auto" }}>
              <table className="w-full">
                <thead className="bg-gray-100 text-gray-500 text-sm text-medium">
                  <tr>
                    <td className=" p-1 border-r ">SNO</td>
                    <td className=" p-1 border-r">NAME</td>
                    <td className=" p-1 border-r">QUANTITY</td>
                    <td className=" p-1 border-r">STRIPS</td>
                    <td className=" p-1 border-r">TABLETS</td>
                    <td className=" p-1 border-r">PRICE</td>
                    <td className=" p-1 border-r">TAX</td>
                    <td className=" p-1 border-r">TOTAL</td>
                    <td className=" p-1 text-center">DELETE</td>
                  </tr>
                </thead>
                <tbody>
                  {addedProduct?.map((value, index) => {
                    return (
                      <tr className=" border-b">
                        <td className="p-1 border-r">{index + 1}</td>
                        <td className="p-1 border-r  text-sm">{value?.name}</td>
                        <td className="p-1 border-r">
                          <Input
                            ref={quantityref.current[index]}
                            name="quantity"
                            type="number"
                            className="text-regular"
                            placeholder="0"
                            value={value?.quantity}
                            onChange={(e) =>
                              calculate_price(e, value?.id, index)
                            }
                          />
                        </td>
                        <td className="p-1 border-r">
                          <Input
                            type="number"
                            name="strips"
                            className="text-regular"
                            placeholder="0"
                            onChange={(e) =>
                              calculate_price(e, value?.id, index)
                            }
                            disabled={!value?.isStrips}
                          />
                        </td>
                        <td className="p-1 border-r">
                          <Input
                            type="number"
                            name="tablets"
                            className="text-regular"
                            placeholder="0"
                            onChange={(e) =>
                              calculate_price(e, value?.id, index)
                            }
                            disabled={!value?.isTablet}
                          />
                        </td>
                        <td className="p-1 text-center border-r">
                          {value?.price}
                        </td>
                        <td className="p-1 text-center border-r">
                          {value?.tax_amount}
                        </td>
                        <td className="p-1 text-center border-r">
                          {value?.total}
                        </td>
                        <td className="p-1 text-center border-r">
                          <span
                            className="cursor-pointer text-xl text-red-500 hover:text-red-900"
                            onClick={() => delete_product(index)}
                          >
                            <DeleteOutlined />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="bg-gray-100 text-gray-500">
              <div className="px-4 py-1 flex justify-between">
                <div>Subtotal</div>
                <div>
                  {(
                    addedProduct?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue.total),
                      0
                    ) -
                    addedProduct?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue.tax_amount),
                      0
                    )
                  ).toFixed(3)}
                </div>
              </div>
              <div className="px-4 py-1 flex justify-between">
                <div>Tax amount</div>
                <div>
                  {addedProduct
                    ?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue.tax_amount),
                      0
                    )
                    ?.toFixed(3)}
                </div>
              </div>
            </div>
            <div className="bg-white">
              <div className="px-4 py-1 flex justify-between">
                <div>Total</div>
                <div>
                  {addedProduct
                    ?.reduce(
                      (acc, currentValue) =>
                        parseFloat(acc) + parseFloat(currentValue.total),
                      0
                    )
                    ?.toFixed(3)}
                </div>
              </div>
            </div>
            <div className="px-4 py-1 bg-gray-200">
              <div className="text-gray-500">Payemnt Method</div>
              <div className="flex justify-between py-2">
                <div className="pr-2 w-full">
                  <div
                    className={
                      paymentType?.includes("CASH")
                        ? "border border-green-500 bg-green-500 text-white p-2 rounded-md w-full text-center cursor-pointer"
                        : "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white p-2 rounded-md w-full text-center cursor-pointer"
                    }
                    onClick={() => add_payment_type("CASH")}
                  >
                    CASH
                  </div>
                  {paymentType?.length > 1 && (
                    <div className="pt-2 w-full">
                      <Input
                        type="number"
                        placeholder="0"
                        disabled={!paymentType?.includes("CASH")}
                      />
                    </div>
                  )}
                </div>
                <div className="pr-2 w-full">
                  <div
                    className={
                      paymentType?.includes("CARD")
                        ? "border border-green-500 bg-green-500 text-white p-2 rounded-md w-full text-center cursor-pointer"
                        : "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white p-2 rounded-md w-full text-center cursor-pointer"
                    }
                    onClick={() => add_payment_type("CARD")}
                  >
                    CARD
                  </div>
                  {paymentType?.length > 1 && (
                    <div className="pt-2 w-full">
                      <Input
                        type="number"
                        placeholder="0"
                        disabled={!paymentType?.includes("CARD")}
                      />
                    </div>
                  )}
                </div>
                <div className="pr-2 w-full">
                  <div
                    className={
                      paymentType?.includes("BANK TRANSFER")
                        ? "border border-green-500 bg-green-500 text-white p-2 rounded-md w-full text-center cursor-pointer"
                        : "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white p-2 rounded-md w-full text-center cursor-pointer"
                    }
                    onClick={() => add_payment_type("BANK TRANSFER")}
                  >
                    BANK TRASFER
                  </div>
                  {paymentType?.length > 1 && (
                    <div className="pt-2 w-full">
                      <Input
                        type="number"
                        placeholder="0"
                        disabled={!paymentType?.includes("BANK TRANSFER")}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="p-4">
              <Button
                type="primary"
                size="large"
                className="w-full text-medium bg-[#07B553] hover:bg-[#14532d]"
                onClick={() => navigate("/app/billing/view")}
              >
                Confirm {" (OMR "}
                {addedProduct
                  ?.reduce(
                    (acc, currentValue) =>
                      parseFloat(acc) + parseFloat(currentValue.total),
                    0
                  )
                  ?.toFixed(3)}
                {") "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pos;
