import React from "react";

const SalesIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g transform="matrix(0.9,0,0,0.9,25.599998474121094,25.59999999712744)">
        <path
          d="M430.584 0H218.147v144.132c0 9.54-7.734 17.274-17.274 17.274H56.741v325.917C56.741 500.951 67.79 512 81.418 512h349.166c13.628 0 24.677-11.049 24.677-24.677V24.677C455.261 11.049 444.212 0 430.584 0zm-97.263 409.763H192.675c-9.54 0-17.274-7.734-17.274-17.274s7.734-17.274 17.274-17.274h140.646c9.54 0 17.274 7.734 17.274 17.274s-7.734 17.274-17.274 17.274zm0-81.261H192.675c-9.54 0-17.274-7.734-17.274-17.274 0-9.54 7.734-17.274 17.274-17.274h140.646c9.54 0 17.274 7.734 17.274 17.274 0 9.54-7.734 17.274-17.274 17.274zm0-81.259H192.675c-9.54 0-17.274-7.734-17.274-17.274s7.734-17.274 17.274-17.274h140.646c9.54 0 17.274 7.734 17.274 17.274s-7.734 17.274-17.274 17.274z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M183.389 0a24.668 24.668 0 0 0-17.448 7.229L63.968 109.198a24.676 24.676 0 0 0-7.229 17.448v.211h126.86V0h-.21z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default SalesIcon;
