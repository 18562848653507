const tables = [
  {
    name: "Purchase",
  },
  {
    name: "Supplier",
  },
  {
    name: "Date",
  },
  {
    name: "Due",
  },
  {
    name: "Received",
  },
  {
    name: "Paid",
  },
  {
    name: "Amount",
  },
  {
    name: "Action",
  },
];

export default tables;
