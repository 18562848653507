import React from "react";

const ReportIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 25 25"
    >
      <g transform="matrix(0.9500000000000003,0,0,0.9500000000000003,0.6249999761581364,0.6275000095367371)">
        <path
          d="M21.9 3.6c0-1.7-1.4-3.1-3.1-3.1h-7.9c-.8 0-1.6.3-2.1.9L4.1 5.8c-.6.5-1 1.4-1 2.3v13.4c0 1.7 1.4 3.1 3.1 3.1h12.6c1.7 0 3.1-1.4 3.1-3.1zM5.1 8c0-.3.2-.6.4-.8l4.6-4.4c.1-.1.4-.3.8-.3h.1v3.8c0 1-.8 1.9-1.9 1.9zm13.5 8.2-3.8 4.3c-.2.2-.5.3-.7.3H14c-.3 0-.6-.2-.8-.4l-2.1-3.2-3.2 3.4c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l4.1-4.3c.2-.2.5-.3.8-.3s.6.2.8.4l2.1 3.2 2.9-3.3c.4-.4 1-.4 1.4-.1s.4 1 0 1.4zm-.3-6.1H14c-.6 0-1-.4-1-1s.4-1 1-1h4.3c.5 0 1 .4 1 1s-.4 1-1 1zm0-4H14c-.6 0-1-.4-1-1s.4-1 1-1h4.3c.5 0 1 .4 1 1s-.4 1-1 1z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ReportIcon;
