const headers = {
  name: "Products",
  navigate: {
    path: [
      {
        name: "Products",
        path: "/app/products",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
