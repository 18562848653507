import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import products from "../../../Data/products.json";
import productpurchase from "../../../Data/productpurchase.json";

const ProductpurchaseReport = () => {
  let location = useLocation();

  const [reports, setReports] = useState();

  const headers = {
    name: "Product purchase",
    components: [
      {
        type: "search",
        name: "search",
        placeholder: "Search",
        value: "",
      },
      {
        type: "range",
        name: "date",
        value: {
          start: "",
          end: "",
        },
      },
    ],
  };

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const fetchData = (tableData) => {
    let data = [];

    if (tableData) {
      tableData?.map((value, index) => {
        data?.push({
          name: value?.name,
          quantity: value?.quantity,
          date: value?.date,
          amount: value?.amount,
        });
      });
    } else {
      productpurchase?.map((value, index) => {
        data?.push({
          name: value?.name,
          quantity: value?.quantity,
          date: value?.date,
          amount: value?.amount,
        });
      });
    }

    setReports(data);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...productpurchase];

    let search = data?.search?.toLowerCase();
    let date = data?.date;

    if (search) {
      tableData = tableData.filter((item) =>
        get_product(item?.name)?.toLowerCase()?.includes(search)
      );
    }

    if (date && date.start && date.end) {
      const startDate = new Date(date.start);
      const endDate = new Date(date.end);

      tableData = tableData.filter((item) => {
        const itemDate = parseDate(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <table className="w-full bg-white">
        <thead className="text-gray-500">
          <tr>
            <td className="border p-4">Name</td>
            <td className="border p-4">Date</td>
            <td className="border p-4">Quantity</td>
            <td className="border p-4">Amount</td>
          </tr>
        </thead>
        <tbody>
          {reports?.map((value, index) => {
            return (
              <tr>
                <td className="border p-4">{get_product(value?.name)}</td>
                <td className="border p-4">{value?.date}</td>
                <td className="border p-4">{value?.quantity}</td>
                <td className="border p-4">{value?.amount}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.quantity),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.amount),
                0
              )
              ?.toFixed(3)}
          </td>
        </tfoot>
      </table>
    </Header>
  );
};

export default ProductpurchaseReport;
