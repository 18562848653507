import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Settings/Users/forms";
import headers from "../../../../Data/Forms/Settings/Users/headers";
import branches from "../../../../Data/branch.json";
import roles from "../../../../Data/roles.json";

const UsersForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
    }
  };

  const handleData = (item) => {};

  const fetchData = async () => {
    let branches_options = [];

    branches?.map((value, index) => {
      branches_options?.push({ value: value?.name, label: value?.name });
    });

    let roles_options = [];

    roles?.map((value, index) => {
      roles_options?.push({ value: value?.name, label: value?.name });
    });

    values[1].options[0].form.options = branches_options;
    values[1].options[1].form.options = roles_options;

    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default UsersForm;
